import React, { useState, useEffect } from 'react';
import './quizCard.css';
import axios from 'axios';

const QuizCard = ({ nextQuiz, onRegisterClick }) => {
  const [nextQuizzes, setNextQuizzes] = useState([])
  const [isMobile, setIsMobile] = useState(false)
  const [quizzesToday, setQuizzesToday] = useState(0);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API}/api/quizzes`)
      .then((res) => {
        const quizzes = res.data;

        // Sort quizzes by startTime (nearest first)
        quizzes.sort(
          (a, b) => new Date(a.startTime).getTime() - new Date(b.startTime).getTime()
        );

        // Get today's date
        const today = new Date();
        const startOfToday = new Date(today.getFullYear(), today.getMonth(), today.getDate());
        const endOfToday = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 1);

        // Filter quizzes that fall within today
        const todayQuizzes = quizzes.filter(
          (quiz) =>
            new Date(quiz.startTime) >= startOfToday &&
            new Date(quiz.startTime) < endOfToday
        );

        // Set the state for quizzes
        setNextQuizzes(quizzes.slice(0, 3)); // Take the first three quizzes
        setQuizzesToday(todayQuizzes.length); // Set the count of today's quizzes
      })
      .catch((err) => console.error(err));
  }, []);

  useEffect(() => {
    // Check the initial width on load
    const checkWidth = () => {
      setIsMobile(window.innerWidth < 768);
    };

    checkWidth(); // Run the check on load

    // Optionally, listen for resize events
    window.addEventListener('resize', checkWidth);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('resize', checkWidth);
    };
  }, []);


  return (
    <div className='flex gap-4 justify-center'>
      {!isMobile ? nextQuizzes?.map((quiz, index) => (
        <div key={index} className={`quiz-card ${index !== 0 && "opacity-80"}`} onClick={index === 0 ? onRegisterClick : undefined}>
          <div className="quiz-header  flex items-center justify-center ">
            <span className="up-next bg-[var(--defaultYellow)] text-center">UP NEXT</span>
            <span className="blitz-number text-center">{quiz.title}</span>
          </div>
          <div className="quiz-details">
            <div className="quiz-datetime">
              {new Date(quiz.startTime).toLocaleDateString('en-GB', {
                day: '2-digit',
                month: 'short',
                year: 'numeric',
                timeZone: 'UTC'
              })} - {new Date(quiz.startTime).toLocaleTimeString('en-GB', {
                hour: '2-digit',
                minute: '2-digit',
                timeZone: 'UTC',
                hour12: false
              })} UTC
            </div>
            <div className="quiz-topic">
              Game topic: {quiz.title}
            </div>
          </div>

          <div className="prize-pools">
            <div className="flex items-center gap-2 justify-center">
              <div className="first-prize"></div>
              <div className="flex flex-col ">

                <div className="prize-amount">{quiz.prize?.currency}{quiz.prize?.amount}</div>
                <div className="prize-label">Total prize pool</div>
              </div>
            </div>
          </div>

          <div className="up-next bg-[var(--defaultYellow)] shadow-lg text-center">+ BONUS ROUND</div>
        </div>
      )) : <div className='flex flex-col items-center gap-0'>
      <p className='text-[var(--defaultYellow)] leading-[0.5rem] text-center'>Quizzes Today: {quizzesToday}</p>
      <div className="quiz-card" onClick={onRegisterClick}>
        
        <div className="quiz-header  flex items-center justify-center">
          <span className="up-next bg-[var(--defaultYellow)] text-center">UP NEXT</span>
          <span className="blitz-number text-center">{nextQuiz.title}</span>
        </div>
        <div className="quiz-details">
          <div className="quiz-datetime">
            {new Date(nextQuiz.startTime).toLocaleDateString('en-GB', {
              day: '2-digit',
              month: 'short',
              year: 'numeric',
              timeZone: 'UTC'
            })} - {new Date(nextQuiz.startTime).toLocaleTimeString('en-GB', {
              hour: '2-digit',
              minute: '2-digit',
              timeZone: 'UTC',
              hour12: false
            })} UTC
          </div>
          <div className="quiz-topic">
            Game topic: {nextQuiz.title}
          </div>
        </div>

        <div className="prize-pools">
          <div className="flex items-center gap-2 justify-center">
            <div className="first-prize"></div>
            <div className="flex flex-col ">

              <div className="prize-amount">{nextQuiz.prize?.amount}{nextQuiz.prize?.currency}</div>
              <div className="prize-label">Total prize pool</div>
            </div>
          </div>
        </div>

        <div className="up-next bg-[var(--defaultYellow)] shadow-lg text-center">+ BONUS ROUND</div>
      </div></div>}

    </div>
  );
};

export default QuizCard;