import './Roadmap.css';
import arrow from '../../../../assets/images/arrow-neon.png'
import caret from '../../../../assets/images/caret-right-white.png'
import arrowY from '../../../../assets/images/longArrowActive.png'
import arrowW from '../../../../assets/images/longArrow.png'
import arrowDownY from '../../../../assets/images/arrowDownActive.png'
import arrowDownW from '../../../../assets/images/arrowDown.png'


const Roadmap = () => {
  return (
    <div className="roadmap-wrapper" id=''>
      <div className="roadmap-content">
        <div className="roadmap-banner">
          <h2 className=''>Powering the Future<br />of Skill-Based Gaming</h2>
        </div>
        <section className="roadmap-container">
          <div style={{ width: '100%', gap: 0 }}>
            <h2 style={{ margin: 0, marginBottom: '1rem' }}>Road Map</h2>
            <p style={{ minWidth: '100%' }}>Stratetic Steps to Deliver a Seamless, Secure, and Rewarding Gaming <br />
              Ecosystem. Through Phased Innovation and Advanced Technological <br />
              Development.</p>
          </div>
          <div className="timeline-container">
            <div className='timeline-desktop'>
              <div className='timeline-top-row'>
                <div className="timeline-number-container">
                  <p className='timeline-phase active'>Phase</p>
                  <p className='timeline-phase-nr active'>1</p>
                  <p className='timeline-phase active'>Done</p>
                </div>
                <img src={arrowY} alt="" style={{ width: '144px', height: '24px' }} />
                <div className="timeline-number-container">
                  <p className='timeline-phase active'>Phase</p>
                  <p className='timeline-phase-nr active'>2</p>
                  <p className='timeline-phase'>In Progress</p>
                </div>
                <img src={arrowW} alt="" style={{ width: '144px', height: '24px' }} />
                <div className="timeline-number-container">
                  <p className='timeline-phase'>Phase</p>
                  <p className='timeline-phase-nr'>3</p>
                  <p className='timeline-phase' style={{ color: '#1A0C2E' }}>a</p>
                </div>
              </div>
              <div className='timeline-bottom-row'>
                <div className='timeline-content-container'>
                  <div className='timeline-content-row'>
                    <img src={arrow} alt="" style={{ height: '28px' }} />
                    <p>Full Scale Branding</p>
                  </div>
                  <div className='timeline-content-row'>
                    <img src={arrow} alt="" style={{ height: '28px' }} />
                    <p>Website User Interface & Experience</p>
                  </div>
                  <div className='timeline-content-row'>
                    <img src={arrow} alt="" style={{ height: '28px' }} />
                    <p>Website Mobile Version</p>
                  </div>
                  <div className='timeline-content-row'>
                    <img src={arrow} alt="" style={{ height: '28px' }} />
                    <p>Front-end Trivia Functionality</p>
                  </div>
                  <div className='timeline-content-row'>
                    <img src={arrow} alt="" style={{ height: '28px' }} />
                    <p>Back-end Website Functionality connected to Trivia</p>
                  </div>
                  <div className='timeline-content-row'>
                    <img src={arrow} alt="" style={{ height: '28px' }} />
                    <p>End-to-End Trivia Testing</p>
                  </div>
                  <div className='timeline-content-row'>
                    <img src={arrow} alt="" style={{ height: '28px' }} />
                    <p>Minimum Viable Product (MVP) of the Trivia</p>
                  </div>
                  <div className='timeline-content-row'>
                    <img src={arrow} alt="" style={{ height: '28px' }} />
                    <p>Detailed documentation on how PvT & PvP will work</p>
                  </div>
                  <div className='timeline-content-row'>
                    <img src={arrow} alt="" style={{ height: '28px' }} />
                    <p>Daily Play Bonus Fully Functional</p>
                  </div>
                </div>
                <div className='timeline-content-container'>
                  <div className='timeline-content-row'>
                    <img src={arrow} alt="" style={{ height: '28px' }} />
                    <p>Player Rankings Implementation</p>
                  </div>
                  <div className='timeline-content-row'>
                    <img src={arrow} alt="" style={{ height: '28px' }} />
                    <p>Platform Status Bonus Implementation</p>
                  </div>
                  <div className='timeline-content-row'>
                    <img src={arrow} alt="" style={{ height: '28px' }} />
                    <p>Initiation of the Power-Up Functionalities on Trivia</p>
                  </div>
                  <div className='timeline-content-row'>
                    <img src={arrow} alt="" style={{ height: '28px' }} />
                    <p>PvP Leaderboards</p>
                  </div>
                  <div className='timeline-content-row'>
                    <img src={arrow} alt="" style={{ height: '28px' }} />
                    <p>Enhanced Trivia Modes</p>
                  </div>
                  <div className='timeline-content-row'>
                    <img src={arrow} alt="" style={{ height: '28px' }} />
                    <p>Referral System Rollout</p>
                  </div>
                  <div className='timeline-content-row'>
                    <img src={arrow} alt="" style={{ height: '28px' }} />
                    <p>Social Integration</p>
                  </div>
                </div>
                <div className='timeline-content-container'>
                  <div className='timeline-content-row'>
                    <img src={arrow} alt="" style={{ height: '28px' }} />
                    <p>New Games Introduced</p>
                  </div>
                  <div className='timeline-content-row padding-left'>
                    <img src={caret} alt="" style={{ height: '28px' }} />
                    <p>Dice Duel Game</p>
                  </div>
                  <div className='timeline-content-row padding-left'>
                    <img src={caret} alt="" style={{ height: '28px' }} />
                    <p>Quick Draw</p>
                  </div>
                  <div className='timeline-content-row padding-left'>
                    <img src={caret} alt="" style={{ height: '28px' }} />
                    <p>Token Chase</p>
                  </div>
                  <div className='timeline-content-row'>
                    <img src={arrow} alt="" style={{ height: '28px' }} />
                    <p>PvP Tournaments</p>
                  </div>
                  <div className='timeline-content-row'>
                    <img src={arrow} alt="" style={{ height: '28px' }} />
                    <p>Marketplace for In-Game Items</p>
                  </div>
                  <div className='timeline-content-row'>
                    <img src={arrow} alt="" style={{ height: '28px' }} />
                    <p>AI-Enhanced Trivia Questions</p>
                  </div>
                  <div className='timeline-content-row'>
                    <img src={arrow} alt="" style={{ height: '28px' }} />
                    <p>Charity and Community Initiatives</p>
                  </div>
                </div>
              </div>
            </div>
            <div className='timeline-mobile'>
              <div className="timeline-number-container gap-0">
                <p className='timeline-phase active'>Phase</p>
                <p className='timeline-phase-nr active -translate-y-10'>1</p>
                <p className='timeline-phase active -translate-y-20'>Done</p>
              </div>
              <div className='timeline-content-container'>
                <div className='timeline-content-row'>
                  <img src={arrow} alt="" style={{ height: '28px' }} />
                  <p>Full Scale Branding</p>
                </div>
                <div className='timeline-content-row'>
                  <img src={arrow} alt="" style={{ height: '28px' }} />
                  <p>Website User Interface & Experience</p>
                </div>
                <div className='timeline-content-row'>
                  <img src={arrow} alt="" style={{ height: '28px' }} />
                  <p>Website Mobile Version</p>
                </div>
                <div className='timeline-content-row'>
                  <img src={arrow} alt="" style={{ height: '28px' }} />
                  <p>Front-end Trivia Functionality</p>
                </div>
                <div className='timeline-content-row'>
                  <img src={arrow} alt="" style={{ height: '28px' }} />
                  <p>Back-end Website Functionality connected to Trivia</p>
                </div>
                <div className='timeline-content-row'>
                  <img src={arrow} alt="" style={{ height: '28px' }} />
                  <p>End-to-End Trivia Testing</p>
                </div>
                <div className='timeline-content-row'>
                  <img src={arrow} alt="" style={{ height: '28px' }} />
                  <p>Minimum Viable Product (MVP) of the Trivia</p>
                </div>
                <div className='timeline-content-row'>
                  <img src={arrow} alt="" style={{ height: '28px' }} />
                  <p>Detailed documentation on how PvT & PvP will work</p>
                </div>
                <div className='timeline-content-row'>
                  <img src={arrow} alt="" style={{ height: '28px' }} />
                  <p>Daily Play Bonus Fully Functional</p>
                </div>
              </div>
              <div className='flex flex-row justify-between w-full items-center'>
                <img src={arrowDownY} alt="" style={{ height: '160px' }} />
                <div className="timeline-number-container">
                  <p className='timeline-phase active'>Phase</p>
                  <p className='timeline-phase-nr active -translate-y-10'>2</p>
                  <p className='timeline-phase -translate-y-20'>In Progress</p>
                </div>
                <div></div> 
              </div>
              <div className='timeline-content-container'>
                <div className='timeline-content-row'>
                  <img src={arrow} alt="" style={{ height: '28px' }} />
                  <p>Player Rankings Implementation</p>
                </div>
                <div className='timeline-content-row'>
                  <img src={arrow} alt="" style={{ height: '28px' }} />
                  <p>Platform Status Bonus Implementation</p>
                </div>
                <div className='timeline-content-row'>
                  <img src={arrow} alt="" style={{ height: '28px' }} />
                  <p>Initiation of the Power-Up Functionalities on Trivia</p>
                </div>
                <div className='timeline-content-row'>
                  <img src={arrow} alt="" style={{ height: '28px' }} />
                  <p>PvP Leaderboards</p>
                </div>
                <div className='timeline-content-row'>
                  <img src={arrow} alt="" style={{ height: '28px' }} />
                  <p>Enhanced Trivia Modes</p>
                </div>
                <div className='timeline-content-row'>
                  <img src={arrow} alt="" style={{ height: '28px' }} />
                  <p>Referral System Rollout</p>
                </div>
                <div className='timeline-content-row'>
                  <img src={arrow} alt="" style={{ height: '28px' }} />
                  <p>Social Integration</p>
                </div>
                <div className='flex flex-row justify-between w-full items-center'>
                  <img src={arrowDownW} alt="" style={{ height: '160px' }} />
                  <div className="timeline-number-container">
                    <p className='timeline-phase translate-y-8'>Phase</p>
                    <p className='timeline-phase-nr'>3</p>
                    <p className='timeline-phase -translate-y-8' style={{ color: '#1A0C2E' }}>a</p>
                    <p>&nbsp;</p>
                  </div>
                </div>
                <div className='timeline-content-container'>
                  <div className='timeline-content-row'>
                    <img src={arrow} alt="" style={{ height: '28px' }} />
                    <p>New Games Introduced</p>
                  </div>
                  <div className='timeline-content-row padding-left'>
                    <img src={caret} alt="" style={{ height: '28px' }} />
                    <p>Dice Duel Game</p>
                  </div>
                  <div className='timeline-content-row padding-left'>
                    <img src={caret} alt="" style={{ height: '28px' }} />
                    <p>Quick Draw</p>
                  </div>
                  <div className='timeline-content-row padding-left'>
                    <img src={caret} alt="" style={{ height: '28px' }} />
                    <p>Token Chase</p>
                  </div>
                  <div className='timeline-content-row'>
                    <img src={arrow} alt="" style={{ height: '28px' }} />
                    <p>PvP Tournaments</p>
                  </div>
                  <div className='timeline-content-row'>
                    <img src={arrow} alt="" style={{ height: '28px' }} />
                    <p>Marketplace for In-Game Items</p>
                  </div>
                  <div className='timeline-content-row'>
                    <img src={arrow} alt="" style={{ height: '28px' }} />
                    <p>AI-Enhanced Trivia Questions</p>
                  </div>
                  <div className='timeline-content-row'>
                    <img src={arrow} alt="" style={{ height: '28px' }} />
                    <p>Charity and Community Initiatives</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Roadmap;    