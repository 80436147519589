import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import './QuestionItem.css'

const QuestionItem = ({ question, questionIndex, onUpdate, onDelete }) => {
  const toggleQuestion = () => {
    onUpdate({ ...question, isOpen: !question.isOpen });
  };

  const addOption = () => {
    if (question.options.length >= 4) return;
    
    const newOption = {
      id: Date.now(),
      text: ''
    };
    
    onUpdate({
      ...question,
      options: [...question.options, newOption]
    });
  };

  const updateOption = (optionIndex, text) => {
    const newOptions = [...question.options];
    newOptions[optionIndex] = { ...newOptions[optionIndex], text };
    onUpdate({ ...question, options: newOptions });
  };

  const deleteOption = (optionId) => {
    const newOptions = question.options.filter(opt => opt.id !== optionId);
    const newCorrectOption = question.correctOption === optionId ? null : question.correctOption;
    onUpdate({ ...question, options: newOptions, correctOption: newCorrectOption });
  };

  const setCorrectOption = (optionId) => {
    const newOptions = question.options.map(opt => ({
      ...opt,
      isCorrect: opt.id === optionId
    }));
    onUpdate({ 
      ...question, 
      options: newOptions,
      correctOption: optionId 
    });
  };

  return (
    <motion.div
      className="question-item"
      layout
      initial={{ opacity: 0, y: -20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -20 }}
    > 
      <div className="question-header" onClick={toggleQuestion}>
        <div className="question-info">
          <span className="question-number">Q{questionIndex + 1}.</span>
          {!question.isOpen && (
            <span className="question-preview">
              {question.question || 'Click to edit question'}
            </span>
          )}
        </div>
        <div className="question-actions">
          <motion.button
            className="delete-btn"
            onClick={(e) => {
              e.stopPropagation();
              onDelete();
            }}
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
          >
            delete
          </motion.button>
          <motion.div
            animate={{ rotate: question.isOpen ? 180 : 0 }}
            transition={{ duration: 0.3 }}
          >
            
          </motion.div>
        </div>
      </div>

      <AnimatePresence>
        {question.isOpen && (
          <motion.div
            className="question-content"
            initial={{ height: 0, opacity: 0 }}
            animate={{ height: 'auto', opacity: 1 }}
            exit={{ height: 0, opacity: 0 }}
            transition={{ duration: 0.3 }}
          >
            <textarea
              className="question-text text-red-500 "
              value={question.question}
              onChange={(e) => onUpdate({ ...question, question: e.target.value })}
              placeholder="Enter your question..."
              rows={2}
            />

            <div className="options-container">
              {question.options.map((option, index) => (
                <motion.div
                  key={index}
                  className="option-item"
                  layout
                  initial={{ opacity: 0, x: -20 }}
                  animate={{ opacity: 1, x: 0 }}
                  exit={{ opacity: 0, x: -20 }}
                >
                  <button
                    className={`correct-option-btn ${question.correctOption === option.id ? 'correct' : ''}`}
                    onClick={() => setCorrectOption(option.id)}
                  >
                    {question.correctOption === option.id ? '✓' : '○'}
                  </button>
                  <input
                    type="text"
                    value={option.text}
                    onChange={(e) => updateOption(index, e.target.value)}
                    placeholder={`Option ${index + 1}`}
                    className="option-input"
                  />
                  <motion.button
                    className="delete-option-btn"
                    onClick={() => deleteOption(option.id)}
                    whileHover={{ scale: 1.1 }}
                    whileTap={{ scale: 0.9 }}
                  >
           delete icon
                  </motion.button>
                </motion.div>
              ))}

              {question.options.length < 4 && (
                <motion.button
                  className="add-option-btn"
                  onClick={addOption}
                  whileHover={{ scale: 1.02 }}
                  whileTap={{ scale: 0.98 }}
                >
                  Add Option
                </motion.button>
              )}
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </motion.div>
  );
};

export default QuestionItem;