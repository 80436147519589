import { color } from 'framer-motion';
import './GameTypes.css';
import pvpIcon from './PvsP.png';
import pvgIcon from './PvsT.png';
import { useState } from 'react';

const GameTypes = () => {
  const [pvpActive, setPvpActive] = useState(false)

  const clickPvp = () => {
    setPvpActive(true)
  }

  const clickPve = () => {
    setPvpActive(false)
  }

  const pvtDetails = [
    "Players compete against the platform itself in PvN games, either individually or as a team.",
    "Anyone can participate without paying entrance fees – the platform and sponsors establish the prize",
    "In PvN games, owning Tamago Token provides marked advantages both in gameplay and rewards to the players with more tokens."
  ];

  const pvpDetails = [
    "In PvP games, players compete against each other, either individually or as a team.",
    "The prize pool is generated by the entrance fees of the players themselves.",
    "Owning more Tamago Token does NOT provide any in-game advantages to the players in PvP.",
    "Matches can be played using Tamago Token or $SOL, depending on the league."
  ];

  return (
    <section className="game-types-container" id='game-types'>
      <div className="game-types-content">
        <div className="game-types-text">
          <h2 style={{ color: 'var(--defaultYellow)' }}>Game Types</h2>
          <p style={{ minWidth: '100%' }}>Discover Exciting Game Modes. Compete against the platform in <br />
            Player vs Tamango, challenge others in Player vs Player. Test your <br />
            skills, earn rewards, and enjoy competitive gaming.</p>
        </div>
      </div>

      <div className="game-types-grid">
        <div className="game-type">
          <div className='flex gap-4 justify-center w-full'>
            <div onClick={clickPve} className={`gametype-btn transition-all ${!pvpActive && 'activeMode'} m-0 flex justify-center items-center`}>
              <div className='h-full flex flex-row items-end pb-4'>
                <p className='text-[var(--defaultRed)] font-bold text-7xl'>P</p>
                <p className='font-bold text-4xl pb-2'>V</p>
                <p className='text-black md:text-[var(--defaultYellow)] font-bold text-7xl'>T</p>
              </div>
            </div>
            <div onClick={clickPvp} className={`gametype-btn md:hidden transition-all ${pvpActive && 'activeMode'} m-0 flex justify-center items-center`}>
              <div className='h-full flex flex-row items-end pb-4'>
                <p className='text-[var(--defaultRed)] font-bold text-7xl'>P</p>
                <p className='font-bold text-4xl pb-2'>V</p>
                <p className='text-black md:text-[var(--defaultYellow)] font-bold text-7xl'>P</p>
              </div>
            </div>
          </div>
          <div className="game-type-header">  
            <h3>PLAYER VS {pvpActive ? 'PLAYER' : 'TAMAGO'}</h3>
          </div>
          <div className="game-type-content m-0 p-0">
            <ul className="feature-list">
              <div className='block md:hidden'>
              {
                pvpActive ? pvpDetails.map((detail, index) => (
                  <li key={index}>
                    <span className="arrow">→</span>
                    <span>{detail}</span>
                  </li>
                )) : pvtDetails.map((detail, index) => (
                  <li key={index}>
                    <span className="arrow">→</span>
                    <span>{detail}</span>
                  </li>
                ))
              }
              </div>
              <div className='hidden md:block'>
                {pvtDetails.map((detail, index) => (
                  <li key={index}>
                    <span className="arrow">→</span>
                    <span>{detail}</span>
                  </li>
                ))}
              </div>
            </ul>
            <div className="diagram w-full flex justify-center">
              <img src={!pvpActive ? pvgIcon : pvpIcon} alt="PvT Diagram" className="diagram-img" />
            </div>
          </div>
        </div>

        <div className="game-type pvp">
          <div className='gametype-btn m-0 flex justify-center items-center'>
            <div className='h-full flex flex-row items-end pb-4'>
              <p className='text-[var(--defaultRed)] font-bold text-7xl'>P</p>
              <p className='font-bold text-4xl pb-2'>V</p>
              <p className='text-[var(--defaultRed)] font-bold text-7xl'>P</p>
            </div>
          </div>
          <div className="game-type-header">
            <h3>PLAYER VS PLAYER</h3>
          </div>
          <div className="game-type-content m-0 p-0">
            <ul className="feature-list">
              {pvpDetails.map((detail, index) => (
                <li key={index}>
                  <span className="arrow">→</span>
                  <span>{detail}</span>
                </li>
              ))}
            </ul>
            <div className="diagram w-full flex justify-center">
              <img src={pvpIcon} alt="PvP Diagram" className="diagram-img" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default GameTypes;
