// src/services/gameService.js
import axios from 'axios';

const API_BASE_URL = `${process.env.REACT_APP_API}/api`; // Replace with your API base URL

// Function to create a new quiz
export const createQuiz = async (formData) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/quizzes/`, { formData });
    return response.data;
  } catch (error) {
    console.error('Error creating quiz:', error.response?.data || error.message);
    throw error;
  }
};

// Function to get all quizzes
export const getAllQuizzes = async () => {
  try {
    const response = await axios.get(`${API_BASE_URL}/quizzes/`);
    return response.data;
  } catch (error) {
    console.error('Error fetching quizzes:', error.response?.data || error.message);
    throw error;
  }
};

// Function to get a quiz by ID
export const getQuizById = async (quizId) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/quizzes/${quizId}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching quiz by ID:', error.response?.data || error.message);
    throw error;
  }
};

// Function to search quizzes by date range
export const searchQuizzes = async (startDate, endDate) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/quizzes/search`, {
      params: { startDate, endDate },
    });
    return response.data;
  } catch (error) {
    console.error('Error searching quizzes:', error.response?.data || error.message);
    throw error;
  }
};

// Function to delete a quiz by ID
export const deleteQuizById = async (quizId) => {
  try {
    const response = await axios.delete(`${API_BASE_URL}/quizzes/${quizId}`);
    return response.data;
  } catch (error) {
    console.error('Error deleting quiz:', error.response?.data || error.message);
    throw error;
  }
};

// Function to update a quiz by ID
export const updateQuizById = async (quizId, formData) => {
  try {
    const response = await axios.put(`${API_BASE_URL}/quizzes/${quizId}`, { formData });
    return response.data;
  } catch (error) {
    console.error('Error updating quiz:', error.response?.data || error.message);
    throw error;
  }
};

// Function to create a new player
export const createPlayer = async (playerData) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/players/`, playerData);
    return response.data;
  } catch (error) {
    console.error('Error creating player:', error.response?.data || error.message);
    throw error;
  }
};

// Function to get all players
export const getAllPlayers = async () => {
  try {
    const response = await axios.get(`${API_BASE_URL}/players/`);
    return response.data;
  } catch (error) {
    console.error('Error fetching players:', error.response?.data || error.message);
    throw error;
  }
};

// Function to search a player by walletId or IP
export const searchPlayer = async (query) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/players/search`, {
      params: query,
    });
    return response.data;
  } catch (error) {
    console.error('Error searching player:', error.response?.data || error.message);
    throw error;
  }
};

// Function to delete a player by walletId
export const deletePlayerByWalletId = async (walletId) => {
  try {
    const response = await axios.delete(`${API_BASE_URL}/players/${walletId}`);
    return response.data;
  } catch (error) {
    console.error('Error deleting player:', error.response?.data || error.message);
    throw error;
  }
};

// Function to add a winner to a quiz
export const addWinnerToQuiz = async (quizId, walletId) => {
  try {
    const response = await axios.put(`${API_BASE_URL}/quizzes/${quizId}/winner`, { walletId });
    return response.data;
  } catch (error) {
    console.error('Error adding winner to quiz:', error.response?.data || error.message);
    throw error;
  }
};