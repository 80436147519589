import React, { useEffect, useState } from 'react';
import './loadingScreen.css';

const LoadingScreen = ({ timeLeft = '00:12', isBonus = false }) => {
  const [BARS, setBARS] = useState(4); // Initial BARS value is 6
  const [maxBars, setMaxBars] = useState(24); // Default max BARS

  // Effect to increase BARS every 0.2 seconds
  useEffect(() => {
    const checkScreenSize = () => {
      const isMobile = window.innerWidth <= 768; // Mobile breakpoint
      setMaxBars(isMobile ? 8 : 24); // Set max bars based on screen size
    };

    checkScreenSize(); // Run on mount
    window.addEventListener('resize', checkScreenSize); // Update on window resize

    return () => window.removeEventListener('resize', checkScreenSize); // Cleanup
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setBARS((prev) => (prev < maxBars ? prev + 1 : prev)); // Stop at maxBars
    }, 400); // 0.2 seconds interval

    return () => clearInterval(interval); // Clean up on unmount
  }, [maxBars]); // Re-run if maxBars changes

  // Function to render BARS amount of divs
  const renderBars = () => {
    return Array.from({ length: BARS }).map((_, index) => (
      <div key={index} className="h-full w-4 bg-[var(--defaultYellow)]"></div>
    ));
  };

  return (
    <div className="loading-screen">
      <h2 className="loading-title">Loading...</h2>

      <div className="progress-bar-container">
        <div className="progress-bar">
          {renderBars()} {/* Dynamically render bars */}
        </div>
      </div>

      <div className="loading-info">
        <div className="next-round">
          <span className="time-text">Don't leave.</span>
          {/* <span className="countdown">{timeLeft}</span> */}
        </div>

        {isBonus && (
          <div className="bonus-badge">
            Bonus Round!
          </div>
        )}
      </div>
    </div>
  );
};

export default LoadingScreen;
