import './Hero.css';
import arcade from './Desktop-Loop.gif';
import arrowR from '../../../../assets/images/arrow-dark.png'
import download from '../../../../assets/images/arrow-dl.png'
import files from '../../../../assets/images/files.png'

const Hero = () => {
    function openInNewTab(url) {
        if (!url) {
            console.error("URL is required.");
            return;
        }

        // Open the URL in a new tab
        const newTab = window.open(url, '_blank');

        // Focus the new tab if it was successfully opened
        if (newTab) {
            newTab.focus();
        } else {
            console.error("Failed to open the new tab. Make sure pop-ups are not blocked.");
        }
    }

    return (
        <section className="hero-container">
            <div className="hero-content">
                <div className="hero-text">
                    <h1 className='hero-heading' style={{ margin: 0 }}>The Most Exciting</h1>
                    <h2 style={{ margin: 0 }} className='hero-subheading'>Way to Engage in Web 3.0!</h2>
                    <p style={{ margin: 0, maxWidth: '100%', width: '100%' }}>Tamago is a skill-based, interactive gaming platform where players can test their knowledge and skills against the platform or compete with others to earn rewards. <br />
                        Join daily quizzes to climb the leaderboard, unlock exclusive rewards, and enhance your in-game perks!</p>
                    <div className="cta-buttons">
                        <button className="primary-btn"
                        >
                            <p className='text-black'>BUY TAMAGO</p>
                            <img src={arrowR} alt="" className='h-4' />
                        </button>
                        <button className="secondary-btn"
                            onClick={() => openInNewTab('https://docs.tamago.fun/')}>
                            <p className="text-black">DOCS</p>
                            <img src={download} alt="" className='h-5' />
                        </button>
                        <button className="tertiary-btn"
                            onClick={() => openInNewTab('https://t.me/tamagoplay')}>
                            <p className="text-black">TELEGRAM</p>
                            <img src={arrowR} alt="" className='h-4' />
                        </button>
                        <button className="fourth-btn"
                            onClick={() => openInNewTab('https://x.com/tamagofun')}>
                            <p className="text-black">TWITTER</p>
                            <img src={arrowR} alt="" className='h-4' />
                        </button>
                        <button className="fifth-btn"
                        >
                            <p className="text-black">CONTRACT</p>
                            <img src={files} alt="" className='h-5' />
                        </button>
                    </div>
                </div>
                <div className="hero-visual">
                    <img src={arcade} alt="Arcade Game" className='desktop-img' />
                    <div className='flex md:hidden flex-col pb-20'>
                        <button className="primary-btn gap-2"
                        >
                            <p className="text-black text-sm">BUY TAMAGO</p>
                            <img src={arrowR} alt="" className='max-h-4 max-w-4' />
                        </button>
                        <button className="secondary-btn gap-2"
                            onClick={() => openInNewTab('https://docs.tamago.fun/')}>
                            <p className="text-black text-sm">DOCS</p>
                            <img src={download} alt="" className='max-h-4 max-w-3' />
                        </button>
                        <button className="tertiary-btn gap-2"
                            onClick={() => openInNewTab('https://t.me/tamagoplay')}>
                            <p className="text-black text-sm">TELEGRAM</p>
                            <img src={arrowR} alt="" className='max-h-4 max-w-4' />
                        </button>
                        <button className="fourth-btn gap-2"
                            onClick={() => openInNewTab('https://x.com/tamagofun')}>
                            <p className="text-black text-sm">TWITTER</p>
                            <img src={arrowR} alt="" className='max-h-4 max-w-4' />
                        </button>
                        <button className="fifth-btn gap-2"
                        >
                            <p className="text-black text-sm">CONTRACT</p>
                            <img src={files} alt="" className='max-h-4 max-w-4' />
                        </button>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Hero;