import React, { useEffect, useState } from "react";
import I1 from "../../../assets/images/ChessAnimation.gif";
import I2 from "../../../assets/images/Animacioni.gif";
import I3 from "../../../assets/images/Chessdown.png";
import I4 from "../../../assets/images/shiritidropdown.png";
import I5 from "../../../assets/images/Footerpattern2.png";
import Navvbar from "../../../assets/images/logoNav.png";
import { Link } from 'react-router-dom';

const ChessPage = () => {
    const gifDuration = 4000; // Duration of GIF in milliseconds
    const [gifSrc1, setGifSrc1] = useState("");
    const [gifSrc2, setGifSrc2] = useState("");

    useEffect(() => {
        // Force reload GIFs by appending a timestamp query
        setGifSrc1(`${I1}?t=${Date.now()}`);
        setGifSrc2(`${I2}?t=${Date.now()}`);

        // Force scroll to top on page refresh
        window.scrollTo(0, 0);

        // Disable scrolling while GIF is playing
        document.body.classList.add("no-scroll");

        // Re-enable scrolling after GIF duration
        const timeout = setTimeout(() => {
            document.body.classList.remove("no-scroll");
        }, gifDuration);

        return () => {
            clearTimeout(timeout);
            document.body.classList.remove("no-scroll"); // Ensure scrolling is re-enabled on unmount
        };
    }, []);

    return (
        <div>
            <header className="w-full pt-2 pb-4 flex justify-center absolute h-12 inset-0 z-[9999]">
                <div className="mx-auto px-4">
                    <Link to="/"><img src={Navvbar} alt="Logo" className="h-8 cursor-pointer"/></Link>
                </div>
            </header>
            <section id="hero" className="relative h-screen flex flex-col items-center justify-center text-center">
                <img
                    id="chessGif"
                    src={gifSrc1}
                    alt="Chess Animation"
                    className="absolute inset-0 w-full h-full desktop-image"
                />
                <img
                    id="chessGifMobile"
                    src={gifSrc2}
                    alt="Chess Animation Mobile"
                    className="absolute inset-0 w-full h-full mobile-image"
                />
            </section>

            {/* Chess Board Section */}
            <section className="relative bg-black flex items-center justify-center topi2">
                <img src={I3} alt="Chess Board" className="desktop-image" />
                <img src={I4} alt="Chess Board Mobile" className="mobile-image" />
            </section>

            {/* Coming Soon Section */}
            <section className="absolute top-[135%] left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-center w-full topiii">
                <h2 className="text-yellow-400 text-3xl md:text-6xl font-bold pixel-font">
                    Coming soon
                </h2>
                <p className="mt-4 text-2xl text-gray-300 max-w-lg mx-auto text-center justify-center custom-font">
                    Dive into thrilling tournaments, outsmart rivals worldwide, and
                    claim your chess crown.
                </p>
            </section>

            {/* Footer */}
            <footer className="relative bg-black">
                <img src={I5} alt="Footer Pattern" className="absolute bottom-0 left-0 w-full" />
            </footer>
        </div>
    );
};

export default ChessPage;