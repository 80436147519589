// import Nintendo from './Nintendo.png';
import Nintendo from '../../../../assets/images/Nintendo-Optimized.gif';
import './FreeToPlay.css';
import arrowDark from '../../../../assets/images/arrow-dark.png'
import arrowYellow from '../../../../assets/images/arrow-yellow.png'

const FreeToPlay = () => {
  return (
    <section className="free-to-play-container">
      <div className="content-wrapper">
        <div className="ftp-top-container">
          <div className='ftp-text-container'>
            <h2 className='ftp-heading'>Free to play</h2>
            <h3 className='text-lg md:text-xl'>For Anyone, Including Non-token Holders</h3>
            <p className="description text-sm">
              Trivia by Tamango is our launch product. It serves as the platform's
              core user engagement and acquisition engine. The beta product will
              launch on the desktop as a browser-based interface. Our goal is to
              launch a mobile offering by the end of the year.
            </p>
          </div>
          <div className="nintendo-image">
            <img src={Nintendo} alt="Nintendo game console" />
          </div>
        </div>

        <div className="text-content mt-8 md:mt-0">
          <div className="features-columns">
            <div className="left-column">
              <div className="feature-item">
                <img src={arrowDark} alt="" className='max-w-6 md:max-w-8' />
                <span>Win real prize money!</span>
              </div>
              <div className="feature-item">
                <img src={arrowDark} alt="" className='max-w-6 md:max-w-8' />
                <span>Players must answer 12 questions correctly in a row.</span>
              </div>
              <div className="feature-item">
                <img src={arrowDark} alt="" className='max-w-6 md:max-w-8' />
                <span>Get to the end to split the prize with other winners!</span>
              </div>
              <div className="feature-item">
                <img src={arrowDark} alt="" className='max-w-6 md:max-w-8' />
                <span>Receive payouts in crypto (Tamago Token or SOL) – directly to your own wallet.</span>
              </div>
            </div>
            <div className="right-column">
              <div className="feature-item">
                <img src={arrowDark} alt="" className='max-w-6 md:max-w-8' />
                <span>The more tokens you hold, the more power-ups you receive and the bigger your prizes.</span>
              </div>
              <div className="feature-item">
                <img src={arrowDark} alt="" className='max-w-6 md:max-w-8' />
                <span>Bring your friends to win perks!</span>
              </div>
              <div className="feature-item dark">
                <img src={arrowYellow} alt="" className='max-w-6 md:max-w-8' />
                <span>Tournament-style trivia, where the questions keep coming until only a certain number of players remain.</span>
              </div>
              <div className="feature-item dark" style={{ marginTop: '-16px' }}>
                <img src={arrowYellow} alt="" className='max-w-6 md:max-w-8' />
                <span>"Family Feud" style games, where community members can engage with the contents of the tournament itself.</span>
              </div>
            </div>
          </div>
        </div>

      </div>
    </section>
  );
};

export default FreeToPlay;