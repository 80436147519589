import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import QuestionsList from './QuestionsList'
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import './AddQuiz.css';

// Separate the QuizBasicInfo into its own component
const QuizBasicInfo = ({ title, startTime, prize, onTitleChange, onStartTimeChange, onPrizeChange }) => {
  return (
    <div className="quiz-basic-info">
      <input
        type="text"
        value={title}
        onChange={(e) => onTitleChange(e.target.value)}
        placeholder="Quiz Title"
        className="quiz-title-input"
      />
      <div className="prize-input-container" style={{ display: 'block', alignItems: 'center' }}>

        <input
          type="number"
          value={prize?.amount}
          onChange={(e) => onPrizeChange({ currency: prize.currency, amount: Number(e.target.value) })}
          placeholder="Prize Amount"
          className="quiz-prize-input"
          min="0"
        />
           <input
          type="text"
          // value={prize?.currency}
          onChange={(e) => onPrizeChange({ currency: e.target.value, amount: prize.amount })}
          placeholder="Currency"
          className="quiz-prize-input"

        />
      </div>

    
      <DatePicker
        selected={startTime}
        onChange={onStartTimeChange}
        showTimeSelect
        timeFormat="HH:mm"
        timeIntervals={15}
        dateFormat="MMMM d, yyyy h:mm aa"
        minDate={new Date()}
        className="quiz-date-picker"
        wrapperClassName="datepicker-wrapper"
      />
    </div>
  );
};

// Separate ErrorDisplay into its own component
const ErrorDisplay = React.memo(({ errors }) => {
  if (!errors || errors.length === 0) return null;
  
  return (
    <motion.div
      className="error-container"
      initial={{ opacity: 0, height: 0 }}
      animate={{ opacity: 1, height: 'auto' }}
      exit={{ opacity: 0, height: 0 }}
      transition={{ duration: 0.3 }}
    >
      {errors.map((error, index) => (
        <motion.div
          key={index}
          className="error-message"
          initial={{ x: -20, opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          transition={{ delay: index * 0.1 }}
        >
          ❌ {error}
        </motion.div>
      ))}
    </motion.div>
  );
});

// Main AddQuiz component
const AddQuiz = ({ onCreateQuiz }) => {
  const [formData, setFormData] = useState({
    title: '',
    startTime: new Date(),
    prize: { currency: '€', amount: 0 },
    questions: [{
      id: Date.now(),
      question: '',
      options: [
        { id: Date.now(), text: '', isCorrect: false },
        { id: Date.now() + 1, text: '', isCorrect: false },
        { id: Date.now() + 2, text: '', isCorrect: false },
        { id: Date.now() + 3, text: '', isCorrect: false }
      ],
      correctOption: null,
      isOpen: true
    }]
  });
  const [errors, setErrors] = useState([]);

  const validateQuiz = () => {
    const newErrors = [];
    
    if (!formData.title.trim()) {
      newErrors.push('Quiz title is required');
    }
    
    if (!formData.startTime) {
      newErrors.push('Start time is required');
    }
    
    if (formData.questions.length === 0) {
      newErrors.push('At least one question is required');
    }

    formData.questions.forEach((question, idx) => {
      if (!question.question.trim()) {
        newErrors.push(`Question ${idx + 1} text is required`);
      }
      
      const hasValidOptions = question.options.some(opt => opt.text.trim());
      if (!hasValidOptions) {
        newErrors.push(`Question ${idx + 1} needs at least 2 valid options`);
      }
      
      const hasCorrectOption = question.options.some(opt => opt.isCorrect);
      if (!hasCorrectOption) {
        newErrors.push(`Question ${idx + 1} needs a correct answer`);
      }
    });

    setErrors(newErrors);
    return newErrors.length === 0;
  };

  const handleSubmit = () => {
    // console.log('handle submit add quiz', formData)
    if (validateQuiz()) {
      onCreateQuiz(formData);
    }
  };

  return (
    <motion.div 
      className="add-quiz-container"
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
    >
      <QuizBasicInfo 
        title={formData.title}
        startTime={formData.startTime}
        prize={formData.prize}
        onTitleChange={(title) => setFormData({ ...formData, title })}
        onStartTimeChange={(date) => setFormData({ ...formData, startTime: date })}
        onPrizeChange={(prize) => setFormData({ ...formData, prize })}
      />
      
      <QuestionsList
        questions={formData.questions}
        onQuestionsUpdate={(questions) => {
          // console.log('questionsquestions', questions)
          
          setFormData({ ...formData, questions })}}
      />

      <AnimatePresence>
        {errors.length > 0 && <ErrorDisplay errors={errors} />}
      </AnimatePresence>

      <motion.button
        className="submit-quiz-btn"
        onClick={handleSubmit}
        whileHover={{ scale: 1.02 }}
        whileTap={{ scale: 0.98 }}
      >
        Create Quiz
      </motion.button>
    </motion.div>
  );
};

export default AddQuiz;
