import player1 from './PlayerRanks-01.png'
import player2 from './PlayerRanks-02.png'
import player3 from './PlayerRanks-03.png'
import player4 from './PlayerRanks-04.png'
import player5 from './PlayerRanks-05.png'
import './PlayerRanking.css'


const PlayerRanking = () => {
  const playerImages = {
    1: player1,
    2: player2,
    3: player3,
    4: player4,
    5: player5
  };

  return (
    <section className="player-ranking-container">
      <div className="player-ranking-content">
        <div className="player-ranking-text">
          <h2 className='pr-heading'>Player Ranking</h2>
          <p style={{minWidth: '100%'}}>
            The more tokens you own, the higher your rank is within the platform. Your <br />
            rank provides access to exclusive in-game clubs, free power-ups, and bigger
            <br /> prizes. You can either purchase the tokens on the open market or win them
            <br />through contests.
          </p>
          <p>
            Ranks are decided purely by the player's token holdings, whether bought or
            won. Each rank receives a variable multiplier as its "weight" when splitting
            prizes.
          </p>
        </div>
        
        <div className="players-grid">
          {[1, 2, 3, 4, 5].map((rank) => (
            <div key={rank} className="player-card">
              <img 
                src={playerImages[rank]} 
                alt={`Rank ${rank} Player`} 
                className="player-image"
              />
              <p className="bonus-range">
                Rank {rank} bonus Range <br />
                <span className="multiplier">
                  {rank === 1 && "1.0x - 1.3x"}
                  {rank === 2 && "1.2x - 1.5x"}
                  {rank === 3 && "1.4x - 1.8x"}
                  {rank === 4 && "1.8x - 2.0x"}
                  {rank === 5 && "1.8x - 2.3x"}
                </span>
              </p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default PlayerRanking; 