import React, { useState, useEffect } from 'react';
import Option from './option';
import './questions.css';
import ProgressBar from './progressBar';
import timerIcon from './../assets/timer-icon.svg';
import OptionHandle from './../optionHandle';

const Questions = ({ questions, onAnswerSubmit, onQuestionComplete }) => {
    const hasValidQuestions = questions && questions.length > 0;

    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    const [answers, setAnswers] = useState([]);
    const [timer, setTimer] = useState(12); // Start with 12 seconds for selection phase
    const [phase, setPhase] = useState('selection');
    const [selectedOption, setSelectedOption] = useState(null);
    const [pendingAnswer, setPendingAnswer] = useState(null);
    const [isComplete, setIsComplete] = useState(false);

    useEffect(() => {
        if (hasValidQuestions) {
            setAnswers(new Array(questions.length).fill(''));
        }
    }, [questions, hasValidQuestions]);

    useEffect(() => {
        if (!hasValidQuestions || isComplete) {
            return () => { };
        }

        // Only set the initial timer value when phase changes
        if (phase === 'selection') {
            setTimer(12);
        } else if (phase === 'review') {
            setTimer(5);
        }

        const timerInterval = setInterval(() => {
            setTimer((prevTimer) => {
                if (prevTimer <= 0) {
                    clearInterval(timerInterval);

                    if (phase === 'selection') {
                        if (selectedOption === null) {
                            setPendingAnswer(false);
                            onAnswerSubmit(currentQuestionIndex, -1, false);
                        }
                        // Update answers state when moving to review phase
                        if (pendingAnswer !== null) {
                            const newAnswers = [...answers];
                            newAnswers[currentQuestionIndex] = pendingAnswer;
                            setAnswers(newAnswers);
                        }
                        setPhase('review');
                    } else if (phase === 'review') {
                        if (pendingAnswer !== null) {
                            const newAnswers = [...answers];
                            newAnswers[currentQuestionIndex] = pendingAnswer;
                            // setAnswers(newAnswers);
                            setPendingAnswer(null);
                        }

                        if (currentQuestionIndex < questions.length - 1) {
                            setCurrentQuestionIndex((prev) => prev + 1);
                            setSelectedOption(null);
                            setPhase('selection');
                            onQuestionComplete();
                        } else {
                            setIsComplete(true);
                            onQuestionComplete(true);
                        }
                    }
                    return 0;
                }
                return prevTimer - 1;
            });
        }, 1000);

        return () => clearInterval(timerInterval);
    }, [phase, currentQuestionIndex, hasValidQuestions, isComplete]);

    useEffect(() => {
        if (isComplete) {
            onQuestionComplete(isComplete);
        }
    }, [isComplete]);

    if (!hasValidQuestions) {
        return null;
    }

    const currentQuestion = questions[currentQuestionIndex];

    const handleOptionClick = (optionIndex) => {
        if (phase === 'selection') {
            const isCorrect = Boolean(currentQuestion.options[optionIndex]?.isCorrect);
            setSelectedOption(optionIndex);

            // Immediately update the answers array
            const newAnswers = [...answers];
            newAnswers[currentQuestionIndex] = isCorrect;
            setAnswers(newAnswers);

            setPendingAnswer(isCorrect);
            onAnswerSubmit(currentQuestionIndex, optionIndex, isCorrect);
        }
    };

    if (isComplete) {
        return null; // Don't render anything when complete
    }

    return (
        <div className="question-container">
            <ProgressBar answers={answers} currentQuestionIndex={currentQuestionIndex} phase={phase} />

            <div className="question-text md:text-3xl font-normal md:font-semibold text-[var(--defaultYellow)] text-center  flex justify-center items-center">
                    <p className='text-center'>{currentQuestion?.question}</p>
            </div>

            <div className="options-grid">
                {currentQuestion?.options.map((option, index) => (
                    <Option
                        key={index}
                        value={String.fromCharCode(65 + index)}
                        text={option.option}
                        isSelected={selectedOption === index}
                        isCorrect={phase === 'review' ? option.isCorrect : undefined}
                        disabled={phase === 'review'}
                    />
                ))}
            </div>

            <div className="timer flex">
                <img src={timerIcon} alt="timer" className="timer-icon" />{`00:${timer < 10 ? `0${timer}` : timer}`}
            </div>
            <div className='flex gap-8 mt-24 md:mt-4'>
                <OptionHandle
                    option="A"
                    onClick={()=>handleOptionClick(0)}
                    isSelected={selectedOption === 'A'}
                />
                <OptionHandle
                    option="B"
                    onClick={()=>handleOptionClick(1)}
                    isSelected={selectedOption === 'B'}
                />
                <OptionHandle
                    option="C"
                    onClick={()=>handleOptionClick(2)}
                    isSelected={selectedOption === 'C'}
                />
                <OptionHandle
                    option="D"
                    onClick={()=>handleOptionClick(3)}
                    isSelected={selectedOption === 'D'}
                /></div>
        </div>

    );
};

export default Questions;
