import './Mission.css';
import videoPlaceholder from '../../../../assets/images/video-placeholder.png';
import coin from '../../../../assets/images/coin.png'
import globe from '../../../../assets/images/globe.png'
import scale from '../../../../assets/images/scale.png'
import shield from '../../../../assets/images/shield.png'
import treasure from '../../../../assets/images/treasure.png'
import trophy from '../../../../assets/images/trophy.png'
import diamond from '../../../../assets/images/diamond.png'

const Mission = () => {
  const missionPoints = [
    {
      icon: globe,
      text: () => (<p>Tamago is a skill-based, social gaming <br className='hidden md:block' /> platform for players around the world.</p>)
    },
    {
      icon: coin,
      text: () => (<p>Players can compete in contests of skill <br className='hidden md:block' /> with winners taking home the prizes.</p>)
    },
    {
      icon: shield,
      text: () => (<p>We encourage friendly competition between <br className='hidden md:block' />  friends and community members.</p>)
    },
    {
      icon: trophy,
      text: () => (<p>Our mission is to create a premier gaming experience which rewards players based on their knowledge or ability..</p>)
    },
    {
      icon: treasure,
      text: () => (<p>We are a natively crypto platform – all <br className='hidden md:block' /> payments are handled in crypto!</p>)
    },
    {
      icon: scale,
      text: () => (<p>We are proactive in our regulatory and <br className='hidden md:block' />  legal affairs to create a safe <br className='hidden md:block' /> ecosystem for all participants.</p>)
    },
  ];

  return (
    <section className="mission-container">
      <div className="scrolling-banner">
        <div className="scrolling-content">
          <span>Compete</span>
          <img src={diamond} alt="" className='h-3 md:h-6' />
          <span>Conquer</span>
          <img src={diamond} alt="" className='h-3 md:h-6' />
          <span>Collect</span>
          <img src={diamond} alt="" className='h-3 md:h-6' />
          <span>Compete</span>
          <img src={diamond} alt="" className='h-3 md:h-6' />
          <span>Conquer</span>
          <img src={diamond} alt="" className='h-3 md:h-6' />
          <span>Collect</span>
          <img src={diamond} alt="" className='h-3 md:h-6' />
          <span>Compete</span>
          <img src={diamond} alt="" className='h-3 md:h-6' />
          <span>Conquer</span>
          <img src={diamond} alt="" className='h-3 md:h-6' />
          <span>Collect</span>
          <img src={diamond} alt="" className='h-3 md:h-6' />
        </div>
      </div>

      <div className="video-preview">
        <img src={videoPlaceholder} alt="Video Preview" />
      </div>
      <section className="mission-text-container" id='mission'>
        <div className="mission-content">
          <h1 className='mission-header'>Our Mission</h1>
          <p className='mission-paragraph'>To create a skill-based, social gaming platform that fosters friendly <br />competition, rewards players for their abilities, and ensures a secure,<br />crypto-powered ecosystem for all participants.</p>
        </div>

        <div className="mission-grid">
          {missionPoints.map((point, index) => (
            <div key={index + 1} className="mission-item">
              <div className="icon">
                <img alt={`Mission ${index + 1}`} src={point?.icon} style={{ maxHeight: '80px' }} />
              </div>
              {point?.text()}
            </div>
          ))}
        </div></section>
    </section>
  );
};

export default Mission;