import React, { useState, useEffect } from 'react';
import QuizCard from './quizCard';
import './registration.css';
import CountdownRegistration from './countdown';

const Registration = ({ nextQuiz, countdownMs, socket, handleRegister, isRegistered, handleWalletIdChange }) => {
  const [view, setView] = useState('welcome');

  // Add effect to automatically show quiz card if there's an upcoming quiz
  // useEffect(() => {
  //   if (nextQuiz?.quizId && nextQuiz?.startTime) {
  //     setView('quizCard');
  //   }
  // }, [nextQuiz]);

  const handleScreenClick = () => {
    if (view === 'welcome') {
      setView('quizCard');
    }
  };

  const handleQuizCardClick = () => {
    if (view === 'quizCard') {
      setView('registration');
    }
  };

  //If there's no next quiz scheduled, show appropriate message
  if (!nextQuiz?.quizId || !nextQuiz?.startTime) {
    return (
      <div className="registration-container">
        <div className="welcome-screen">
          <h1 className='md:pt-20'>No Upcoming Quiz</h1>
          <p className=' md:text-lg'>Please check back later</p>
        </div>
      </div>
    );
  }   

  return (
    <div className="registration-container">
      {view === 'welcome' && (
        <div className="welcome-screen" onClick={handleScreenClick}>
          <h1 className='md:pt-20'>Welcome to TRIVIA</h1>
          <p className="press-start">(press to start)</p>
        </div>
      )}

       {view === 'quizCard' && ( 
           <QuizCard 
            nextQuiz={nextQuiz}
            onRegisterClick={() => setView('registration')}
          /> 
       )} 

      {view === 'registration' && (
        <CountdownRegistration 
          nextQuiz={nextQuiz}
          socket={socket}
          handleRegister={handleRegister}
          handleWalletIdChange={handleWalletIdChange}
        />
       )} 
    </div>
  );
};

export default Registration;