import './Footer.css';
import logo from '../../../../assets/images/footerLogo.png'

const Footer = () => {
  const socialLinks = [
    { name: 'Twitter', url: '#', icon: 'X' },
    { name: 'Discord', url: '#', icon: 'D' },
    { name: 'Telegram', url: '#', icon: 'T' },
    { name: 'Medium', url: '#', icon: 'M' },
    { name: 'LinkedIn', url: '#', icon: 'L' },
    { name: 'GitHub', url: '#', icon: 'G' }
  ];

  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="footer-logo">
          <img  alt="Tamago" className="tamago-logo" src={logo} />
        </div>
        <h2 className="footer-title">Stay in Touch!</h2>
        <div className="social-links">
          {socialLinks.map((link, index) => (
            <a 
              key={index}
              // href={link.url}
              className="social-link"
              target="_blank"
              rel="noopener noreferrer"
              disabled={true}
            >
              {link.icon}
            </a>
          ))}
        </div>
        <div className="copyright">
          Tamago © 2025
        </div>
      </div>
    </footer>
  );
};

export default Footer; 