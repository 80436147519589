// src/components/QuizPanel/QuizPanel.js
import React, { useState } from 'react';
import AddQuiz from './AddQuiz';
import QuizzesTable from './QuizzesTable';
import { useQuizData } from '../../hooks/useQuizData';
import { deleteQuizById } from '../../services/gameService';

const   QuizPanel = () => {
  const [loggedIn, setLoggedIn] = useState(false)
  const { quizzes, loading, error, fetchQuizzes, addQuiz, setQuizzes } = useQuizData();
  const [userName, setUserName] = useState('')
  const [password, setPassword] = useState('')

  const USERNAME = process.env.REACT_APP_USERNAME;
  const PASSWORD = process.env.REACT_APP_PASSWORD;

  const handleCreateQuiz = async (formData) => {
    try {
      // formData should match the quizSchema structure
      const quizData = {
        title: formData.title,
        questions: formData.questions.map(q => ({
          question: q.question,
          options: q.options.map(opt => ({
            option: opt.text,
            isCorrect: opt.isCorrect
          }))
        })),
        startTime: new Date(formData.startTime),
        winners: [],
        started: false,
        prize: formData.prize
      };

      await addQuiz(quizData);
      // Optionally refresh the quiz list after creation
      fetchQuizzes();
    } catch (error) {
      console.error('Error creating quiz:', error);
    }
  };

  const handleEditQuiz = (quiz) => {
    // Logic for quiz editing
    // console.log('Edit Quiz:', quiz);
  };

  const handleDeleteQuiz = async (quizId) => {
    try {
      await deleteQuizById(quizId);
      // Update local state by filtering out the deleted quiz
      const updatedQuizzes = quizzes.filter(quiz => quiz._id !== quizId);
      setQuizzes(updatedQuizzes);
    } catch (error) {
      console.error('Error deleting quiz:', error);
    }
  };

  const handleLogIn = () => {
    if(userName == USERNAME && password == PASSWORD){
      setLoggedIn(true)
    }else {
      window.alert("Incorrect Username or Password")
    }
  }

  if (loading) {
    return <div>Loading quizzes...</div>;
  }

  if (error) {
    return <div>Error loading quizzes: {error}</div>;
  }

  if(!loggedIn){
    return <div className='w-screen h-screen flex justify-center items-center'>
      <div className='flex flex-col gap-4 p-8 rounded-lg border-gray-600 border'>
        <input type="text" placeholder='Username' className='p-2 border rounded-md' value={userName} onChange={(e)=>{setUserName(e.target.value)}} />
        <input type="password" placeholder='Password' className='p-2 border rounded-md' value={password} onChange={(e)=>{setPassword(e.target.value)}} />
        <button onClick={handleLogIn}>Log In</button>
      </div>
    </div>
  }

  return (
    <div className="quiz-panel">
      <AddQuiz onCreateQuiz={handleCreateQuiz} />
      <QuizzesTable
        quizzes={quizzes}
        onEdit={handleEditQuiz}
        onDelete={handleDeleteQuiz}
      />
    </div>
  );
};

export default QuizPanel;
