// src/hooks/useQuizData.js
import { useState, useEffect } from 'react';
import {
  getAllQuizzes,
  getQuizById,
  createQuiz,
  deleteQuizById,
  updateQuizById,
  addWinnerToQuiz
} from '../services/gameService';

export const useQuizData = () => {
  const [quizzes, setQuizzes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // Fetch all quizzes
  const fetchQuizzes = async () => {
    setLoading(true);
    try {
      const data = await getAllQuizzes();
      setQuizzes(data);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  // Fetch a quiz by ID
  const fetchQuizById = async (quizId) => {
    setLoading(true);
    try {
      const quiz = await getQuizById(quizId);
      return quiz;
    } catch (err) {
      setError(err.message);
      return null;
    } finally {
      setLoading(false);
    }
  };

  // Create a new quiz
  const addQuiz = async (formData) => {
    try {
      const newQuiz = await createQuiz(formData);
      setQuizzes((prev) => [...prev, newQuiz]);
    } catch (err) {
      setError(err.message);
    }
  };

  // Delete a quiz
  const removeQuiz = async (quizId) => {
    try {
      await deleteQuizById(quizId);
      setQuizzes((prev) => prev.filter((quiz) => quiz._id !== quizId));
    } catch (err) {
      setError(err.message);
    }
  };

  // Update a quiz
  const editQuiz = async (quizId, formData) => {
    try {
      const updatedQuiz = await updateQuizById(quizId, formData);
      setQuizzes((prev) =>
        prev.map((quiz) => (quiz._id === quizId ? updatedQuiz : quiz))
      );
    } catch (err) {
      setError(err.message);
    }
  };

  // Add a winner to a quiz
  const addWinner = async (quizId, walletId) => {
    try {
      const updatedQuiz = await addWinnerToQuiz(quizId, walletId);
      setQuizzes((prev) =>
        prev.map((quiz) => (quiz._id === quizId ? updatedQuiz : quiz))
      );
    } catch (err) {
      setError(err.message);
    }
  };

  // Automatically fetch quizzes on component mount
  useEffect(() => {
    fetchQuizzes();
  }, []);

  return {
    quizzes,
    loading,
    error,
    fetchQuizzes,
    fetchQuizById,
    addQuiz,
    removeQuiz,
    editQuiz,
    addWinner,
    setQuizzes
  };
};
