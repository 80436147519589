import React, { useState } from 'react';
import Header from './sections/Header';
import Hero from './sections/Hero';
import Mission from './sections/Mission';
import Roadmap from './sections/Roadmap';
import GameTypes from './sections/GameTypes';
import FreeToPlay from './sections/FreeToPlay';
import PlayerRanking from './sections/PlayerRanking';
import Footer from './sections/Footer';
import PowerUps from './sections/PowerUps';
import Bonuses from './sections/Bonuses';
import Preview from './sections/Preview';
import navbarClose from '../../assets/images/navClose.png'
import logo from '../../assets/images/logo.png'
import navBtnMob from '../../assets/images/navBtnMob.png'
import navBtnMobChess from '../../assets/images/fcefb929-853d-480d-abde-5dc8afa1181e.png'
import { Link } from 'react-router-dom';
import './sections/Header/Header.css';

const Home = () => {
  const [navOpen, setNavOpen] = useState(false)

  const toggleNav = () => {
    setNavOpen((prevNavOpen) => {
      const newNavOpen = !prevNavOpen;

      // Update the body's overflow property based on the new state
      if (newNavOpen) {
        document.body.style.overflow = 'hidden'; // Disable scrolling
      } else {
        document.body.style.overflow = ''; // Restore default
      }

      return newNavOpen;
    });
  }

  return (
    <div className="home-container bg-[var(--darkPurple)] no-padding relative">
      <div className={`${!navOpen && '-mt-[100vh]'} transition-all h-[100vh] flex flex-col justify-between w-[100vw] bg-[var(--darkPurple)] absolute top-0 inset-0 z-[9999] p-8 pb-28 lg:hidden`}>
        <div className='flex flex-row w-full justify-end'>
          <img src={navbarClose} alt="" className='cursor-pointer' onClick={toggleNav} />
        </div>
        <div className='flex flex-col gap-4'>
          <img src={logo} alt="" className='h-12 w-10' />
          <a href='#overview' onClick={toggleNav} className='text-[var(--defaultYellow)] font-bold text-3xl'>OVERVIEW</a>
          <a href='#mission' onClick={toggleNav} className='text-[var(--defaultYellow)] font-bold text-3xl'>OUR MISSION</a>
          <a href='#roadmap' onClick={toggleNav} className='text-[var(--defaultYellow)] font-bold text-3xl'>ROAD MAP</a>
          <a href='#game-types' onClick={toggleNav} className='text-[var(--defaultYellow)] font-bold text-3xl'>GAME TYPES</a>
          <Link to={'/play'}>
            <img src={navBtnMob} alt="" width={144} />
          </Link>
          <Link to={'/chess'}>
            <img src={navBtnMobChess} alt="" width={144} />
          </Link>
          
        </div>
      </div>
      <Header toggleNav={toggleNav} />
      <main className="">
        <Hero />
        <Mission />
        <Roadmap />
        <GameTypes />
        <FreeToPlay />
        <PlayerRanking />
        <PowerUps />
        <Bonuses />
        <Preview />
      </main>
      <Footer />
    </div>
  );
};

export default Home;
