import React, { useState, useEffect } from 'react';
import checkmark from '../../../../assets/images/8bitCheck.svg'
import './countdown.css';

const formatCountdown = (startTime) => {
  const now = new Date().getTime();
  const startTimeMs = new Date(startTime).getTime();
  const milliseconds = startTimeMs - now;

  if (milliseconds <= 0) {
    return (
      <div className="countdown-display">
        <div className="quiz-started-message">
          Quiz has started
          <div className="home-link">
            <a href="/">Go back to home page</a>
          </div>
        </div>
      </div>
    );
  }

  const totalSeconds = Math.floor(milliseconds / 1000);
  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);
  const seconds = totalSeconds % 60;

  const timeDigits = `${hours.toString().padStart(2, '0')}${minutes.toString().padStart(2, '0')}${seconds.toString().padStart(2, '0')}`.split('');

  return (
    <div className="countdown-display">
      <div className="digit">{timeDigits[0]}</div>
      <div className="digit">{timeDigits[1]}</div>
      <div className="digit-separator">:</div>
      <div className="digit">{timeDigits[2]}</div>
      <div className="digit">{timeDigits[3]}</div>
      <div className="digit-separator">:</div>
      <div className="digit">{timeDigits[4]}</div>
      <div className="digit">{timeDigits[5]}</div>
    </div>
  );
};

const CountdownRegistration = ({ socket, nextQuiz, handleRegister, handleWalletIdChange }) => {
  const [walletId, setWalletId] = useState('');
  const [isConfirmed, setIsConfirmed] = useState(false);

  const handleRegisterClick = (e) => {
    e.preventDefault();
    if (walletId) {
      handleRegister(walletId);
      setIsConfirmed(true);
    }
  };

  return (
    <div className="registration-container-countdown">
      <div className="countdown-section">
        <div className="countdown-label">Starting in...</div>
        {formatCountdown(nextQuiz.startTime)}
      </div>
      <div className="address-section">
        <label className="address-label">Address:</label>
        <form onSubmit={handleRegisterClick} className="registration-form" style={{ width: '100%' }}>
          <div className="input-group">
            <input
              type="text"
              value={walletId}
              onChange={(e) => {
                handleWalletIdChange(e)
                setWalletId(e.target.value)
              }}
              placeholder="Address here"
              required
              className="wallet-input"
              disabled={isConfirmed}
            />
            <button type="submit" className={`confirmed-status ${isConfirmed ? 'bg-[#90EE90] hover:bg-[#52ef5d]' : 'bg-[#ff6b6b] hover:bg-[#ff3c3c]'}`}>
              {!isConfirmed && 'Confirm'}
              {isConfirmed && <img src={checkmark} className='h-3' />}
            </button>
          </div>
        </form>
      </div>
      <div className="warning-text">PLEASE DO NOT REFRESH</div>
    </div>
  );
};

export default CountdownRegistration;
