import React, { useState } from 'react';
import './OptionHandle.css';
import optionA from '../../../assets/images/option-a.svg';
import optionB from '../../../assets/images/option-b.svg';
import optionC from '../../../assets/images/option-c.svg';
import optionD from '../../../assets/images/option-d.svg';
import kungji from '../../../assets/images/kungji.svg'

const OptionHandle = ({ option, onClick, isSelected }) => {
  const [isClicked, setIsClicked] = useState(false);

  const handleClick = () => {
    setIsClicked(true);
    onClick && onClick(option);
    
    setTimeout(() => {
      setIsClicked(false);
    }, 200);
  };

  const getOptionImage = (option) => {
    switch(option.toLowerCase()) {
      case 'a':
        return optionA;
      case 'b':
        return optionB;
      case 'c':
        return optionC;
      case 'd':
        return optionD;
      default:
        return optionA;
    }
  };

  return (
    <div 
      className={`option-handle ${isClicked || isSelected ? 'clicked' : ''}`}
      onClick={handleClick}
    >
      <img 
        src={getOptionImage(option)}
        alt={`Option ${option}`}
        className="option-image"
      />
      <img 
        src={kungji}
        alt={`Option ${option}`}
        className="option-kungj"
      />
    </div>
  );
};

export default OptionHandle;
