import './PowerUps.css';
import extraHeart from '../../../../assets/videos/Heart.gif';  // You'll need to add these
import clairvoyance from '../../../../assets/videos/Clairvoyance.gif';
import bomb from '../../../../assets/videos/Bomb.gif';
import timeFreeze from '../../../../assets/videos/Time_freeze.gif';


const PowerUps = () => {
  const powerUpItems = [
    {
      icon: extraHeart,
      title: 'Extra Heart',
      description: 'Choose a wrong answer and survive'
    },
    {
      icon: clairvoyance,
      title: 'Clairvoyance',
      description: 'Take a real time peek of other players\' answers'
    },
    {
      icon: bomb,
      title: 'Bomb',
      description: 'Delete one wrong answer from the choices'
    },
    {
      icon: timeFreeze,
      title: 'Time Freeze',
      description: 'Get a few extra seconds to answer'
    }
  ];

  return (
    <section className="powerups-container">
      <div className="powerups-content">
        <h1>Power-Ups</h1>
        <p>
        Power-Ups can be purchased with our token or provided for free to high rank players!
        </p>
        
        <div className="powerups-grid">
          {powerUpItems.map((item, index) => (
            <div key={index} className="powerup-item">
              <div className="powerup-icon">
                <img src={item.icon} alt={item.title} />
              </div>
              <div className="powerup-text">
                <h3>{item.title}</h3>
                <p>{item.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default PowerUps;
