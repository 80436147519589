import React from 'react';
import './container (2).css';
import TriviaContent from '../content/index';

const TriviaContainer = ({ children }) => {
  return (
    <div className="trivia-container">
        <div className="trivia-inner-container">
          <TriviaContent>
            {children}
          </TriviaContent>
        </div>
    </div>
  );
};

export default TriviaContainer;
