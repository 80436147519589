import React from 'react';
import optionA from './option-a.svg';
import optionB from './option-b.svg';
import optionC from './option-c.svg';
import optionD from './option-d.svg';
import './option.css';

const Option = ({ 
  value = 'A', 
  text = 'Option A', 
  onClick, 
  isSelected = false,
  isCorrect,
  disabled = false
}) => {
  const optionImages = {
    A: optionA,
    B: optionB,
    C: optionC,
    D: optionD
  };

  const getOptionClassName = () => {
    let className = 'option';
    if (isSelected) className += ' selected';
    if (disabled) {
      if (isCorrect) {
        className += ' correct';
      } else if (isSelected) {
        className += ' incorrect';
      }
    }
    return className;
  };

  return (
    <div 
      className={`${getOptionClassName()} gap-0 flex`}
      onClick={!disabled ? onClick : undefined}
    >
      <img 
        src={optionImages[value]} 
        alt={`Option ${value}`} 
        className="option-image pb-2.5 m-0"
      />
      <p className="option-text m-0">{text}</p>
    </div>
  );
};

export default Option;
