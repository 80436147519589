import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import QuestionItem from './QuestionItem';

const QuestionsList = ({ questions, onQuestionsUpdate }) => {
  const addQuestion = () => {
    if (questions.length >= 12) return;
    
    const baseTime = Date.now();
    const newQuestion = {
      id: baseTime,
      question: '',
      options: Array(4).fill().map((_, index) => ({
        id: baseTime + index,
        text: '',
        isCorrect: false
      })),
      correctOption: null,
      isOpen: true
    };
    
    onQuestionsUpdate([...questions, newQuestion]);
  };

  

  return (
    <div className="questions-list">
      <AnimatePresence>
        {questions.map((question, index) => (
          <QuestionItem
            key={question.id}
            question={question}
            questionIndex={index}
            onUpdate={(updatedQuestion) => {
              const newQuestions = [...questions];
              newQuestions[index] = updatedQuestion;
              onQuestionsUpdate(newQuestions);
            }}
            onDelete={() => {
              const newQuestions = questions.filter(q => q.id !== question.id);
              onQuestionsUpdate(newQuestions);
            }}
          />
        ))}
      </AnimatePresence>

      {questions.length < 12 && (
        <motion.button
          className="add-question-btn"
          onClick={addQuestion}
          whileHover={{ scale: 1.02 }}
          whileTap={{ scale: 0.98 }}
        >
          + Add Question
        </motion.button>
      )}
    </div>
  );
};

export default QuestionsList;