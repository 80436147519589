// src/components/QuizPanel/QuizzesTable.js
import React, { useState } from 'react';
import { useQuizData } from '../../hooks/useQuizData';
import './QuizTable.css';

const QuizzesTable = ({ onEdit, onDelete }) => {
  const { quizzes, loading, error, removeQuiz } = useQuizData();
  const [expandedQuizId, setExpandedQuizId] = useState(null);

  if (loading) {
    return <div>Loading quizzes...</div>;
  }

  if (error) {
    return <div>Error loading quizzes: {error}</div>;
  }

  const handleRowClick = (quizId) => {
    setExpandedQuizId(expandedQuizId === quizId ? null : quizId);
  };

  return (
    <div className="quizzes-table-container">
      <h2>Your Quizzes</h2>
      <table className="quizzes-table">
        <thead>
          <tr>
            <th>Title</th>
            <th>Questions</th>
            <th>Start Time</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {quizzes?.map((quiz) => (
            <React.Fragment key={quiz._id}>
              <tr 
                onClick={() => handleRowClick(quiz._id)}
                className={`quiz-row ${expandedQuizId === quiz._id ? 'expanded' : ''}`}
              >
                <td>{`${new Date(quiz.startTime).toLocaleDateString()} - ${quiz.title}`}</td>
                <td>{quiz.questions?.length}</td>
                <td>{new Date(quiz.startTime).toLocaleTimeString()}</td>
                <td onClick={(e) => e.stopPropagation()}>
                  <button onClick={() => onEdit(quiz)}>Edit</button>
                  <button onClick={() => removeQuiz(quiz._id)}>Delete</button>
                </td>
              </tr>
              {expandedQuizId === quiz._id && (
                <tr className="winners-row">
                  <td colSpan="4">
                    <div className="winners-section">
                      
                      {quiz.winners && quiz.winners.length > 0 ? (
                        <div className="flex gap-5">
                          <p className="text-black">Winners:</p>
                          {quiz.winners.map((winner, index) => (
                            <p className="text-black" key={index}>
                              {winner}
                            </p>
                          ))}
                          
                        </div>
                      ) : (
                        <p className="text-black">No winners yet</p>
                      )}
                    </div>
                  </td>
                </tr>
              )}
            </React.Fragment>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default QuizzesTable;
