import React from 'react'
import './BonusCardsMobile.css'

const BonusCardsMobile = ({ platformActive, clickPlatform, clickDaily, platformStatuses }) => {
    return (
        <div className='bonusCardMobile'>
            <div className='flex gap-4 justify-center w-full'>
                <div onClick={clickDaily} className={`gametype-btn transition-all ${!platformActive && 'activeMode'} m-0 flex justify-center items-center`}>
                    <div className='h-full flex flex-row items-end pb-4'>
                        <p className='text-[var(--defaultRed)] font-bold text-7xl'>P</p>
                        <p className='font-bold text-4xl pb-2'>V</p>
                        <p className='text-black md:text-[var(--defaultYellow)] font-bold text-7xl'>T</p>
                    </div>
                </div>
                <div onClick={clickPlatform} className={`gametype-btn md:hidden transition-all ${platformActive && 'activeMode'} m-0 flex justify-center items-center`}>
                    <div className='h-full flex flex-row items-end pb-4'>
                        <p className='text-[var(--defaultRed)] font-bold text-7xl'>P</p>
                        <p className='font-bold text-4xl pb-2'>V</p>
                        <p className='text-black md:text-[var(--defaultYellow)] font-bold text-7xl'>P</p>
                    </div>
                </div>
            </div>
            <div className="game-type-header">
                <h3 className='font-bold'>{platformActive ? 'PLATFORM STATUS' : 'DAILY'} BONUS</h3>
            </div>
            <>
                <p style={{ marginTop: '-0.75rem' }} className='text-[var(--defaultBlack)] text-sm text-center'>
                    {!platformActive ? 'If users play every day (or whenever the games are available), they receive a resetting multiplier to their weight.'
                        : 'Frequent play and item purchases are rewarded with permanent, higher platform status which provide in-game bonuses, high-priority customer service, and random gifts!'}
                </p>
                <>
                    {!platformActive ?
                        <>
                            <div>
                                <h4 style={{ textAlign: 'center', marginTop: '0.5rem', marginBottom: '0.5rem' }} className='text-[var(--defaultBlack)] text-sm text-center font-bold'>Example</h4>
                                <p className='text-[var(--defaultBlack)] text-sm text-center'>Day 1 = 1.1x, Day 2 = 1.2x... Day 5 = 1.5x</p>
                                <p style={{ marginTop: '0.75rem' }} className='text-[var(--defaultBlack)] text-sm text-center'>
                                    If they keep playing after day 5, they retain the max
                                    multiplier. If they miss a day, the bonus resets.
                                </p>
                            </div>
                            <div className="bonus-ranges">
                                <h4 style={{ margin: 0 }} className='text-[var(--defaultBlack)] text-sm text-center font-bold'>DAILY PLAY BONUS RANGES</h4>
                                <p className='text-[var(--defaultBlack)] text-sm text-center'>1.1x - 1.5x</p>
                            </div>
                        </> :
                        <div className="status-grid">
                            {platformStatuses.map((status, index) => (
                                <div key={index} className="status-item m-0">
                                    <img src={status.icon} alt={status.name} className='m-0' />
                                    <span className='m-0 capitalize font-bold text-black text-xs'>{status.name}</span>
                                    <span className={`multiplier m-0`}>{status.multiplier}</span>
                                </div>
                            ))}
                        </div>
                    }
                </>
            </>
        </div>
    )
}

export default BonusCardsMobile