import React, { useEffect } from 'react';
import './content.css';

const TriviaContent = ({ children }) => {
  useEffect(() => {
    // Allow copy-paste inside .wallet-input, block everywhere else
    const isAllowedInput = (element) => element.closest('.wallet-input');

    // ❌ Disable right-click everywhere except in .wallet-input
    const disableRightClick = (e) => {
      if (!isAllowedInput(e.target)) {
        e.preventDefault();
      }
    };

    // ❌ Block DevTools & Copy/Paste Shortcuts Globally (except .wallet-input)
    const disableShortcuts = (e) => {
      if (
        (e.ctrlKey || e.metaKey) && // Windows (Ctrl) / Mac (Cmd)
        ['c', 'v', 'x'].includes(e.key.toLowerCase()) && // Copy, Paste, Cut
        !isAllowedInput(e.target) // Allow inside .wallet-input
      ) {
        e.preventDefault();
      }
      if ((e.ctrlKey && e.shiftKey && ['i', 'j'].includes(e.key.toLowerCase())) || e.key === 'F12') {
        e.preventDefault(); // Block DevTools
      }
    };

    // ❌ Block Copy and Paste Globally (except in .wallet-input)
    const handleCopyPaste = (e) => {
      if (!isAllowedInput(e.target)) {
        e.preventDefault();
      }
    };

    // ❌ Block pasting via `beforeinput` (except in .wallet-input)
    const handleBeforeInput = (e) => {
      if (e.inputType === 'insertFromPaste' && !isAllowedInput(e.target)) {
        e.preventDefault();
      }
    };

    // Attach event listeners
    document.addEventListener('contextmenu', disableRightClick);
    document.addEventListener('keydown', disableShortcuts);
    document.addEventListener('copy', handleCopyPaste);
    document.addEventListener('paste', handleCopyPaste);
    document.addEventListener('beforeinput', handleBeforeInput);

    // Cleanup listeners on unmount
    return () => {
      document.removeEventListener('contextmenu', disableRightClick);
      document.removeEventListener('keydown', disableShortcuts);
      document.removeEventListener('copy', handleCopyPaste);
      document.removeEventListener('paste', handleCopyPaste);
      document.removeEventListener('beforeinput', handleBeforeInput);
    };
  }, []);

  return <div className="trivia-content">{children}</div>;
};

export default TriviaContent;
