import './Bonuses.css';
import dailyBonus from './daily-bonus.png';
import vip from './vip.png';
import rank1 from './rankIcons/001.png';
import rank2 from './rankIcons/002.png';
import rank3 from './rankIcons/003.png';
import rank4 from './rankIcons/004.png';
import rank5 from './rankIcons/005.png';
import rank6 from './rankIcons/006.png';
import { useEffect, useState } from 'react';
import BonusCardsMobile from '../../cards/BonusCardsMobile'

const Bonuses = () => {
  const [platformActive, setPlatformActive] = useState(false)
  const [isMobile, setIsMobile] = useState(false)

  const clickPlatform = () => {
    setPlatformActive(true)
  }

  const clickDaily = () => {
    setPlatformActive(false)
  }

  useEffect(() => {
    // Check the initial width on load
    const checkWidth = () => {
      setIsMobile(window.innerWidth < 768);
    };

    checkWidth(); // Run the check on load

    // Optionally, listen for resize events
    window.addEventListener('resize', checkWidth);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('resize', checkWidth);
    };
  }, []);

  const platformStatuses = [
    { name: 'BRONZE', multiplier: '1.1x', icon: rank1 },
    { name: 'SILVER', multiplier: '1.2x', icon: rank2 },
    { name: 'GOLD', multiplier: '1.3x', icon: rank3 },
    { name: 'PLATINUM', multiplier: '1.5x', icon: rank4 },
    { name: 'DIAMOND', multiplier: '1.8x', icon: rank5 },
    { name: 'BLACK DIAMOND', multiplier: '2.2x', icon: rank6 },
  ];

  return (
    <section className="bonuses-container">
      <div className="bonuses-content">
        <h1>Bonuses</h1>
        <p className="subtitle">
          Earn rewards daily with multipliers and exclusive perks through <br />
          consistent play and higher platform status levels!
        </p>
        <>
          {isMobile ? <BonusCardsMobile platformActive={platformActive}
            platformStatuses={platformStatuses}
            clickDaily={clickDaily}
            clickPlatform={clickPlatform}
          /> :
            <div className="bonus-cards">
              {/* Daily Play Bonus Card */}
              <div className="bonus-card">
                <div className="bonus-icon">
                  <img src={dailyBonus} alt="Daily Play Bonus" />
                </div>
                <h3 className='mt-1'>DAILY PLAY BONUS</h3>
                <p style={{ marginTop: '0.5rem' }}>
                  If users play every day (or whenever the games are available), <br />
                  they receive a resetting multiplier to their weight.
                </p>
                <div>
                  <h4 style={{ textAlign: 'center', margin: 0, marginTop: '1.5rem', marginBottom: '1rem' }} className='font-bold'>Example</h4>
                  <p>Day 1 = 1.1x, Day 2 = 1.2x... Day 5 = 1.5x</p>
                  <p style={{ marginTop: '1.5rem' }}>
                    If they keep playing after day 5, they retain the max
                    multiplier. If they miss a day, the bonus resets.
                  </p>
                </div>
                <div className="bonus-ranges">
                  <h4 style={{ margin: 0 }} className='font-bold'>DAILY PLAY BONUS RANGES</h4>
                  <p>1.1x - 1.5x</p>
                </div>
              </div>

              {/* Platform Status Bonus Card */}
              <div className="bonus-card">
                <div className="bonus-icon">
                  <img src={vip} alt="Platform Status Bonus" />
                </div>
                <h3 className='mt-1'>PLATFORM STATUS BONUS</h3>
                <p>
                  Frequent play and item purchases are rewarded with
                  permanent, higher platform status which provide in-game
                  bonuses, high-priority customer service, and random gifts!
                </p>
                <div className="status-grid">
                  {platformStatuses.map((status, index) => (
                    <div key={index} className="status-item">
                      <img src={status.icon} alt={status.name} />
                      <span className='font-bold'>{status.name}</span>
                      <span className="multiplier">{status.multiplier}</span>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          }
        </>
      </div>
    </section>
  );
};

export default Bonuses;
