import './Header.css';
import logo from '../../../../assets/images/logoNav.png';
import burger from '../../../../assets/images/hamburger.png'
import { Link } from 'react-router-dom';

const Header = ({ toggleNav, logoOnly, onLogoClick }) => {
  if (logoOnly) {
    return (
      // <header className="w-full header-container py-4">
      <header className="w-full pt-2 pb-4 flex justify-center absolute h-12 inset-0 z-[9999]">
        <div className="mx-auto px-4">
          <img 
            src={logo} 
            alt="Logo" 
            className="h-8 cursor-pointer" 
            onClick={onLogoClick}
          />
        </div>
      </header>
    );
  }
  
  // Return full header for home page
  return (
    <header className="header-container">
      <div className="header-content">
        <Link to={'/'} className="logo">
          <img src={logo} alt="Tamago" />
        </Link>
        <nav className="nav-links">
          <a href="#overview">OVERVIEW</a>
          <a href="#mission">OUR MISSION</a>
          <a href="#roadmap">ROAD MAP</a>
          <a href="#game-types">GAME TYPES</a>
          <Link to="/play" ><button href="" className="trivia-btn">TRIVIA</button></Link>
          <Link to="/chess" href=""><button href="" className="trivia-btn">CHESS</button></Link>
        </nav>
        <img src={burger} alt="" className='h-6 cursor-pointer lg:hidden' onClick={toggleNav} />
      </div>
    </header>
  );
};

export default Header;