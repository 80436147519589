import React from 'react';
import correct from '../../../../assets/images/correct.svg';
import wrong from '../../../../assets/images/wrong.svg';
import grey from '../progressBar/assets/grey.jpeg';
import './progressBar.css';

const ProgressBar = ({ answers = [], currentQuestionIndex, phase }) => {
  const totalQuestions = answers.length;

  return (
    <div className="progress-container flex flex-col justify-center md:mt-5">
      <div className="question-counter">
        Q:{currentQuestionIndex + 1}/{totalQuestions}
      </div>
      <div className="progress-indicators">
        {answers.map((answer, index) => (
          <img
            key={index}
            src={
              index < currentQuestionIndex || (index === currentQuestionIndex && phase === 'review')
                ? (answer === true ? correct : wrong)
                : grey
            }
            alt={
              index < currentQuestionIndex || (index === currentQuestionIndex && phase === 'review')
                ? (answer === true ? "Correct answer" : "Wrong answer")
                : "Unanswered question"
            }
            className="progress-indicator"
          />
        ))}
      </div>
    </div>
  );
};

export default ProgressBar;
