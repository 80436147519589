import './Preview.css';
import preview1 from './1.png';
import preview2 from './2.png';
import preview3 from './3.png';
import { useState } from 'react';
import caretRight from '../../../../assets/images/caret-right-white.png'
import caretLeft from '../../../../assets/images/caret-left.png'

const Preview = () => {
  const [currentPosition, setCurrentPosition] = useState(0);
  const [email, setEmail] = useState('');

  const previewSlides = [
    {
      image: preview1,
      alt: "Free to play preview"
    },
    {
      image: preview2,
      alt: "The Most Fun preview"
    },
    {
      image: preview3,
      alt: "Game menu preview"
    }
  ];

  const nextSlide = () => {
    if (currentPosition > -(previewSlides.length - 3)) {
      setCurrentPosition(prev => prev - 1);
    }
  };

  const prevSlide = () => {
    if (currentPosition < 0) {
      setCurrentPosition(prev => prev + 1);
    }
  };

  const handleSubscribe = (e) => {
    e.preventDefault();
    // Handle subscription logic here
    setEmail('');
  };

  return (
    <section className='preview-section'>
      <div className="preview-container">
        {/* <h1 className="preview-title">Preview</h1>
        <div className="preview-carousel">
          <button
            className="carousel-button prev"
            onClick={prevSlide}
            disabled={currentPosition === 0}
          >
            <img src={caretLeft} alt="" height={32} />
          </button>
          <div className="carousel-viewport">
            <div
              className="carousel-content"
              style={{
                transform: `translateX(${currentPosition * 320}px)`,
              }}
            >
              {previewSlides.map((slide, index) => (
                <div key={index} className="preview-frame">
                  <img
                    src={slide.image}
                    alt={slide.alt}
                    className="preview-image"
                  />
                </div>
              ))}
            </div>
          </div>
          <button
            className="carousel-button next"
            onClick={nextSlide}
            disabled={currentPosition === -(previewSlides.length - 3)}
          >
            <img src={caretRight} alt="" height={32} />
          </button>
        </div> */}
        <div className="newsletter-section">
          <h2 className="newsletter-title">Press Start Now!</h2>
          <p className="newsletter-description">
          Subscribe to receive regular updates and information with our newsletter
          </p>
          <form onSubmit={handleSubscribe} className="newsletter-form">
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Your email"
              className="newsletter-input"
              required
            />
            <button type="submit" className="subscribe-button">
              SUBSCRIBE
            </button>
          </form>
        </div>
      </div>
    </section>
  );
};

export default Preview;
