import React, { useEffect, useState } from 'react';
import trophyIcon from './../assets/trophy-icon.svg';
import lostIcon from './../assets/you-lost-icon.svg';
import './QuizCompletion.css';
import { useNavigate } from 'react-router-dom';

const QuizCompletion = ({
  score,
  totalQuestions,
  basePrize,
  winners,
  multiplier,
  isWinner,
  quizEndState,
  walletId,
  nextQuiz,
  countdown,
  countdownMs,
  isBonusRound = false,
  bonusWinner
}) => {
  const navigate = useNavigate();

  const [hasStarted, setHasStarted] = useState(false);
  const [bonusCountdown, setBonusCountdown] = useState(20);

  useEffect(() => {
    // Check if quiz has already started
    const now = new Date().getTime();
    const quizStartTime = new Date(quizEndState?.startTime).getTime();
    setHasStarted(now >= quizStartTime);
  }, [quizEndState?.startTime]);

  useEffect(() => {
    if (!isBonusRound && bonusCountdown > 0) {
      const timer = setInterval(() => {
        setBonusCountdown((prev) => prev - 1);
      }, 1000);

      return () => clearInterval(timer);
    }
  }, [bonusCountdown, isBonusRound]);

  // Handle loading state
  if (isWinner === null) {
    return (
      <div className="completion-screen loading">
        <h1>Calculating Results...</h1>
        <div className="loading-indicator" />
        <p>Please wait while we determine the winners</p>
      </div>
    );
  }

  return (
    <div className={`completion-screen ${isWinner ? 'winner' : 'loser'}`}>
      <div className="result-icon">
        <img src={isWinner ? trophyIcon : lostIcon} alt={isWinner ? "Trophy" : "Lost"} className='completion-icon' />
      </div>

      <div className="content-section gap-3">
        <h1 className="completion-message">
          {isBonusRound ? 'BONUS WINNER:' : isWinner ? 'YOU WIN!' : 'YOU LOST'}
        </h1>
        <div className="username">
          {isBonusRound ? bonusWinner : walletId}
        </div>
        <h3 className="completion-message">
          {!isWinner && 'Better luck next time'}
        </h3>
        {!isBonusRound && (
          <p className='text-[var(--defaultBlack)] m-0 text-center'>
            Bonus round starting in {bonusCountdown}...
          </p>
        )}

        {isWinner && (
          <>
            <p className="multiplier-info">
              Active Multiplier: {multiplier}x. Play every day for bigger rewards.
            </p>

            <div className="prize-calculation">
              <div className="calculation-row">
                <div className='flex flex-col gap-0 w-[64px] md:w-[88px] items-center font-semibold'>
                  <div className='polygony h-6 md:h-8 z-10 bg-[var(--defaultYellow)] w-full flex justify-center items-center'>PRIZE</div>
                  <div className='polygony bg-[#C3F15E] -translate-y-1 flex items-center justify-center h-8 md:h-12 w-full'>{basePrize.amount}</div>
                </div>
                <div className='flex flex-col gap-0 w-[88px] md:w-[112px] items-center font-semibold'>
                  <div className='polygony h-6 md:h-8 z-10 bg-[#DD4634] text-[var(--defaultYellow)] w-full flex justify-center items-center'>WINNERS</div>
                  <div className='polygony bg-[#C3F15E] -translate-y-1 flex items-center justify-center h-8 md:h-12 w-full'>{winners.length}</div>
                </div>
                <div className='flex flex-col gap-0 w-[112px] md:w-[128px] items-center font-semibold'>
                  <div className='polygony h-6 md:h-8 z-10 bg-[#002859] text-[var(--defaultYellow)] w-full flex justify-center items-center'>MULTIPLIER</div>
                  <div className='polygony bg-[#C3F15E] -translate-y-1 flex text-sm items-center justify-center h-8 md:h-12 w-full'>{winners.length > 0
                    ? (basePrize.amount / winners.length).toFixed(2)
                    : basePrize.amount}{basePrize.currency} X {multiplier} = {((basePrize.amount / winners.length) * multiplier).toFixed(2)}{basePrize.currency}</div>
                </div>

              </div>
              {/* <div className="calculation-row">
                <span className="label">{basePrize.amount}</span>
                <span className="label">/</span>
                <span className="label">{winners.length}</span>
                <span className="label">=</span>
                <span className="label">
                  {winners.length > 0 
                    ? (basePrize.amount / winners.length).toFixed(2) 
                    : basePrize.amount}{basePrize.currency} X {multiplier} = {((basePrize.amount / winners.length) * multiplier).toFixed(2)}{basePrize.currency}
                </span>
              </div> */}
            </div>
          </>
        )}
        {isBonusRound && (
          <div className='flex items-center max-h-[3.5rem] gap-1 md:gap-2 -mt-4 md:mt-0'>
            <div className='w-[88px] md:w-[262px] h-[2px] translate-y-2 bg-[#94216A]'></div>
            <h5
              className="completion-message text-underline cursor-pointer text-lg"
              style={{ color: '#94216A' }}
              onClick={() => {
                window.location.reload();
              }}
            >
              Main Menu
            </h5>
          </div>
        )}
      </div>
    </div>
  );
};

export default QuizCompletion;
