import React, { useState, useEffect } from 'react';
import { useQuizData } from '../../hooks/useQuizData';
import { io } from 'socket.io-client'; // Import socket.io-client
import './trivia.css';
import WalletRegistration from './WalletRegistration';
import QuizCountdown from './QuizCountdown';
import ActiveQuiz from './ActiveQuiz';
import QuizCompletion from './quizCompletion';
import TriviaContainer from './container';
import QuizCard from './registration/quizCard';
import Registration from './registration';
import Questions from './questions/index';
import LoadingScreen from './LoadingScreen/index';
import Header from '../home/sections/Header';
import OptionHandle from './optionHandle';
import arrowR from '../../assets/images/arrow-dark.png'

const dummyQuiz = {
  _id: '6758df9c1942d53534a2fcda',
  title: 'quiz',
  questions: [
    {
      question: 'awdawdwad',
      options: [{
        option: 'New Zealand',
        isCorrect: true
      }, {
        option: '2',
        isCorrect: false
      }, {
        option: '3',
        isCorrect: false
      }, {
        option: '4',
        isCorrect: false
      }],
      _id: '6758df9c1942d53534a2fcdb'
    }, {
      question: 'awdawdwad',
      options: [{
        option: '1',
        isCorrect: true
      }, {
        option: '2',
        isCorrect: false
      }, {
        option: '3',
        isCorrect: false
      }, {
        option: '4',
        isCorrect: false
      }],
      _id: '6758df9c1942d53534a2fcdb'
    }
  ],
  startTime: new Date('2024-12-11T00:43:00.000Z'),
  winners: [],
  players: ['1'],
  started: false,
  prize: {
    currency: '$',
    amount: 2
  },
  __v: 1
}

const socket = io(process.env.REACT_APP_API); // Connect to the server

const TriviaGame = () => {
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [score, setScore] = useState(0);
  const [selectedOption, setSelectedOption] = useState(null);
  const [timeLeft, setTimeLeft] = useState(5);
  const [isQuizCompleted, setIsQuizCompleted] = useState(false);
  const [countdown, setCountdown] = useState(null);
  const [walletId, setWalletId] = useState(''); // State for wallet ID
  const [registrationMessage, setRegistrationMessage] = useState(''); // State for registration message
  const [isRegistered, setIsRegistered] = useState(false);
  const [questionPhase, setQuestionPhase] = useState('selection'); // 'selection' or 'review'
  const [multiplier, setMultiplier] = useState(1); // Add this line for streak multiplier
  const [winners, setWinners] = useState([]);
  const [isWinner, setIsWinner] = useState(null);
  const [showLoadingScreen, setShowLoadingScreen] = useState(false);
  const [nextQuiz, setNextQuiz] = useState({ startTime: null, quizId: null, prize: null, title: null });
  const [countdownMs, setCountdownMs] = useState(null); // Add this state variable
  const [quizEndState, setQuizEndState] = useState(null); // 'loading', 'won', or 'lost'
  const [isBonusRound, setIsBonusRound] = useState(false)
  const [bonusWinner, setBonusWinner] = useState(null)

  const { quizzes, loading, error, fetchQuizzes, fetchQuizById, addWinner } = useQuizData();
  const [selectedQuiz, setSelectedQuiz] = useState(null);

  const currentQuestion = selectedQuiz?.questions[currentQuestionIndex];

  function openInNewTab(url) {
    if (!url) {
        console.error("URL is required.");
        return;
    }

    // Open the URL in a new tab
    const newTab = window.open(url, '_blank');

    // Focus the new tab if it was successfully opened
    if (newTab) {
        newTab.focus();
    } else {
        console.error("Failed to open the new tab. Make sure pop-ups are not blocked.");
    }
}

  // Handle selecting an option
  const handleOptionSelect = (index) => {
    if (questionPhase === 'selection') {
      setSelectedOption(index);
    }
  };

  // Handle moving to the next question
  const handleNext = (selectedOption) => {
    if (selectedOption !== null) {
      const isCorrect = currentQuestion.options[selectedOption]?.isCorrect;

      // Update score if the selected option is correct
      if (isCorrect) {
        setScore((prev) => prev + 1);
      }
      // Check if all questions were answered correctly at the end of the quiz
      const isLastQuestion = currentQuestionIndex === selectedQuiz?.questions.length - 1;
      const updatedScore = isCorrect ? score + 1 : score;

      if (isLastQuestion) {
        setIsQuizCompleted(true);
        // Check if user scored maximum
        if (updatedScore === selectedQuiz?.questions?.length) {
          addWinner(selectedQuiz._id, walletId);
        }
        return; // Stop further execution for the last question
      }
    }
    // Move to the next question
    setSelectedOption(null);
    setTimeLeft(15);
    setCurrentQuestionIndex((prev) => prev + 1);
  };

  // Handle wallet ID input change
  const handleWalletIdChange = (e) => {
    setWalletId(e.target.value);
  };

  // Handle registration form submission
  const handleRegister = (walletId) => {
    if (walletId) {
      socket.emit('register', { quizId: nextQuiz.quizId, walletId });
      // console.log('register', walletId);
      setIsRegistered(true);
    }
  };

  useEffect(() => {
    socket.on('registered', (message) => {
      setRegistrationMessage(message.message);
      setIsRegistered(true);
      setMultiplier(message.multiplier);
    });

    return () => {
      // socket.off('registered');
    };
  }, []);

  useEffect(() => {
    socket.on('next-quiz', (data) => {
      if (data.message !== 'ok') {
        setNextQuiz({ startTime: null, quizId: null, prize: null, title: null });
        setCountdownMs(null);
      } else {
        // console.log('next-quiz', data);
        const startTimeMs = new Date(data.startTime).getTime();
        const nowMs = new Date().getTime();
        const timeUntilStart = startTimeMs - nowMs;

        setCountdownMs(timeUntilStart);
        setNextQuiz({
          startTime: new Date(data.startTime),
          quizId: data.quizId,
          prize: data.prize,
          title: data.title
        });
      }
    });
    // console.log('nextQuiz', nextQuiz);
  }, []);

  useEffect(() => {
    // Listen for "quiz-start" event from the server
    socket.on('quiz-start', (quizData) => {
      // console.log('quiz-start', quizData)
      setSelectedQuiz(quizData);
      setCurrentQuestionIndex(0);
      setScore(0);
      setSelectedOption(null);
      setTimeLeft(12);
      setIsQuizCompleted(false);
    });

    // Listen for quiz-end event
    socket.on('quiz-end', (results) => {
      setShowLoadingScreen(false);
      setIsQuizCompleted(true);
      setWinners(results.winners || []);
      const userIsWinner = results.winners?.includes(walletId);
      setIsWinner(userIsWinner);
      setQuizEndState(userIsWinner ? 'won' : 'lost');
    });

    return () => {
      socket.off('quiz-start');
      socket.off('quiz-end');
    };
  }, [walletId]);

  useEffect(() => {
    // Listen for "quiz-start" event from the server
    socket.on('bonus-start', () => {
      setIsBonusRound(true)
      setIsQuizCompleted(false)
      setShowLoadingScreen(true)
    });

    // Listen for quiz-end event
    socket.on('bonus-end', (results) => {
      // console.log(results)
      if (results?.bonusWinner) {
        // console.log(results?.bonusWinner)
        setBonusWinner(results?.bonusWinner)
      }
      const userIsWinner = results?.winners?.includes(walletId);
      setIsWinner(userIsWinner);
      setShowLoadingScreen(false); // Hide loading screen
      setIsQuizCompleted(true)
      setWinners(results?.winners);
    });

    return () => {
      socket.off('quiz-start');
      socket.off('quiz-end');
    };
  }, [walletId]);

  // Add countdown timer for next quiz
  useEffect(() => {
    if (nextQuiz?.startTime && nextQuiz?.quizId) {
      const timer = setInterval(() => {
        const now = new Date().getTime();
        const startTime = new Date(nextQuiz.startTime).getTime();
        const timeLeft = startTime - now;

        if (timeLeft <= 0) {
          setCountdown(null);
          clearInterval(timer);
          // You might want to trigger quiz fetch here
          // fetchQuiz(nextQuiz.quizId);
        } else {
          const days = Math.floor(timeLeft / (1000 * 60 * 60 * 24));
          const hours = Math.floor((timeLeft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
          const minutes = Math.floor((timeLeft % (1000 * 60 * 60)) / (1000 * 60));
          const seconds = Math.floor((timeLeft % (1000 * 60)) / 1000);
          setCountdown({ days, hours, minutes, seconds });
        }
      }, 1000);

      return () => clearInterval(timer);
    }
  }, [nextQuiz]);

  // Add useEffect for question timer logic
  useEffect(() => {
    if (!selectedQuiz || isQuizCompleted) return;

    let timer;
    if (questionPhase === 'selection') {
      // Selection phase timer (5 seconds)
      setTimeLeft(5);
      timer = setInterval(() => {
        setTimeLeft((prev) => {
          if (prev <= 1) {
            setQuestionPhase('review');
            return 5; // Start review phase timer
          }
          return prev - 1;
        });
      }, 1000);
    } else {
      // Review phase timer (5 seconds)
      timer = setInterval(() => {
        setTimeLeft((prev) => {
          if (prev <= 1) {
            handleNext(selectedOption);
            setQuestionPhase('selection');
            return 5;
          }
          return prev - 1;
        });
      }, 1000);
    }

    return () => clearInterval(timer);
  }, [questionPhase, selectedQuiz, isQuizCompleted]);

  // Modify the Questions component's onQuestionComplete handler
  const handleQuestionComplete = (isQuizComplete) => {
    if (isQuizComplete) {
      setShowLoadingScreen(true);
      setQuizEndState('loading');
    } else {
      setCurrentQuestionIndex(prev => prev + 1);
      setQuestionPhase('selection');
    }
  };

  // Add navigation function
  const handleLogoClick = () => {
    window.location.href = '/'; // Simple redirect to home page
  };

  const handleOptionClick = (option) => {
    // console.log(`Option ${option} was clicked`);
    setSelectedOption(option);
  };

  return (
    <div className='flex flex-col h-screen max-h-screen justify-end items-end '>
      <Header
        logoOnly={true}
        onLogoClick={handleLogoClick}
      />
      <TriviaContainer>
        {!selectedQuiz ? (
          <Registration
            nextQuiz={nextQuiz}
            countdownMs={countdownMs}
            socket={socket}
            handleRegister={handleRegister}
            isRegistered={isRegistered}
            handleWalletIdChange={handleWalletIdChange}
          />
        ) : isQuizCompleted ? (
          <QuizCompletion
            score={score}
            totalQuestions={selectedQuiz?.questions?.length}
            isWinner={isWinner}
            winners={winners}
            basePrize={selectedQuiz?.prize}
            multiplier={multiplier}
            quizEndState={quizEndState}
            walletId={walletId}
            nextQuiz={nextQuiz}
            countdown={countdown}
            countdownMs={countdownMs}
            isBonusRound={isBonusRound}
            bonusWinner={bonusWinner}
          />
        ) : showLoadingScreen ? (
          <LoadingScreen
            timeLeft={countdown ?
              `${String(countdown.hours).padStart(2, '0')}:${String(countdown.minutes).padStart(2, '0')}:${String(countdown.seconds).padStart(2, '0')}`
              : "00:00:00"
            }
            isBonus={isBonusRound}
          />
        ) : (

          <Questions
            questions={selectedQuiz?.questions}
            onAnswerSubmit={(questionIndex, selectedOptionIndex, isCorrect) => {
              if (selectedOptionIndex !== null) {
                if (isCorrect) {
                  setScore((prev) => prev + 1);
                }
              }
            }}
            onQuestionComplete={(isQuizComplete) => {
              if (isQuizComplete) {
                // Check if all questions were answered correctly
                const allQuestionsCorrect = score == selectedQuiz?.questions?.length;
                if (allQuestionsCorrect) {
                  addWinner(selectedQuiz._id, walletId);
                }
                setShowLoadingScreen(true);
              } else {
                setCurrentQuestionIndex(prev => prev + 1);
                setQuestionPhase('selection');
              }
            }}
          />
        )}
        <div className={`flex min-w-20 md:hidden justify-center
          ${!selectedQuiz ? 'mt-[278px]' : isQuizCompleted ? 'mt-[248px]' : 'mt-[304px]'}`}>
          {/* <button className="buy-btn text-xs"
          >
            <p className='text-black'>BUY $TAMGO</p>
            <img src={arrowR} alt="" className='h-[14px]' />
          </button> */}
        </div>
      </TriviaContainer>
    </div>
  );
// 278, 248

};


export default TriviaGame

