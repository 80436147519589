import './App.css';
import { useEffect, useState } from 'react';
import { BrowserRouter, Routes, Route, Link } from 'react-router-dom';
import TriviaGame from './components/trivia';
import QuizPanel from './components/quizPanel';
import Home from './components/home/index';
import Test from './components/Test';
import io from 'socket.io-client';
import ChessPage from './components/home/sections/ChessPage';

const socket = io(process.env.REACT_APP_API);

function App() {
  const [nextQuiz, setNextQuiz] = useState({ startTime: null, quizId: null });

  useEffect(() => {
    socket.on('connect', (data) => {
    });
    socket.on('next-quiz', (data) => {
      if (data.message !== 'ok') {
        // No upcoming quizzes
        setNextQuiz({ startTime: null, quizId: null });
      } else {
        // Store the quiz data
        setNextQuiz({
          startTime: new Date(data.startTime),
          quizId: data.quizId
        });
      }
    });
    // Cleanup socket listeners
    return () => {
      socket.off('connect');

    };
  }, []);

  useEffect(() => {

  }, [])

  return (
    <BrowserRouter>
      <div className="App overflow-x-hidden" style={styles.appContainer}>
        {/* Navigation Header */}
        {/* <nav style={styles.nav}>
          <div style={styles.logo}>TAKTIKO</div>
          <div style={styles.navLinks}>
            <Link to="/play" style={styles.link}>PLAY</Link>
            <Link to="/admin" style={styles.link}>ADMIN</Link>
          </div>
        </nav> */}

        {/* Main Content Area */}
        <main style={styles.mainContent}>
          <Routes>
            <Route path="/chess" element={<ChessPage />} />
            <Route path="/home" element={<Home nextQuiz={nextQuiz} />} />
            <Route path="/play" element={<TriviaGame socket={socket} />} />
            <Route path="/admin" element={<QuizPanel />} />
            <Route path="/" element={<Home nextQuiz={nextQuiz} />} />
            {/* <Route path="/test" element={<Test />} /> */}
          </Routes>
        </main>

      
      </div>
    </BrowserRouter>
  );
}

// Updated styles
const styles = {
  appContainer: {
    minHeight: '100vh',
    backgroundColor: 'white', // Dark purple background
    color: 'gray',
    display: 'flex',
    flexDirection: 'column'
  },
  nav: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    padding: '1rem',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  logo: {
    fontSize: '24px',
    fontWeight: 'bold',
    color: '#FFD700' // Gold color for logo
  },
  navLinks: {
    display: 'flex',
    gap: '1rem'
  },
  link: {
    color: 'white',
    textDecoration: 'none',
    padding: '0.5rem 1rem',
    borderRadius: '4px',
    backgroundColor: '#444',
    transition: 'background-color 0.3s',
    ':hover': {
      backgroundColor: '#666'
    }
  },
  mainContent: {
    flex: 1,
    // padding: '2rem',
    // maxWidth: '1200px',
    margin: '0 auto',
    width: '100%'
  },
  footer: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    padding: '1rem'
  },
  footerContent: {
    maxWidth: '1200px',
    margin: '0 auto',
    textAlign: 'center'
  }
};

export default App;
